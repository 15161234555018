/**
 * option-sync-item.mixin.js
 * Is a mixin that provides common functionality for synchronizing values across components that use it. It works in
 * conjunction with the OptionsSyncContainer component. The mixin mainly consists of the following parts:
 *
 * Setup function:
 * The setup function uses the inject function from the Composition API to get the syncContext object provided by the
 * OptionsSyncContainer component. If the syncContext object is not found, it sets a default object with sync set to
 * false and an empty update function.
 *
 * Mounted lifecycle hook:
 * The mounted lifecycle hook adds a watcher to the syncContext object. If the sync property is true and the component's
 * value is different from the updatedValue in the syncContext, it emits an 'input' event with the updatedValue.
 *
 * Watch:
 * A watch is added to the value property of the component. If the syncContext.sync is true, the syncContext.update
 * function is called with the new value.
 *
 * Methods:
 * A single method called syncValue is included in the mixin, which checks if syncContext.sync is true and calls the
 * syncContext.update function with the updated value. This method must be called by the component that uses the mixin
 * in order to update the syncContext object.
 *
 * In summary, the option-sync-item.mixin.js provides a way to synchronize values across components that use it,
 * working together with the OptionsSyncContainer component. It watches for changes in the value property and the
 * syncContext object and ensures that the values are updated accordingly when the sync feature is enabled.
 */

import { inject, watch } from '@vue/composition-api';

export default {
    // Inject the `syncContext` from the parent container
    setup() {
        const syncContext = inject('syncContext', {
            sync: false,
            update: () => {},
        });

        return { syncContext };
    },
    // Watch the `syncContext` for changes and update the component's value accordingly
    mounted() {
        watch(() => this.syncContext, (newValue) => {
            if (newValue.sync && JSON.stringify(this.value) !== JSON.stringify(newValue.updatedValue)) {
                this.$emit('input', newValue.updatedValue);
            }
        }, { deep: true });
    },
    // Watch the component's value and update the `syncContext` if the `sync` state is active
    watch: {
        value: {
            deep: true,
            handler(newValue) {
                if (this.syncContext.sync) {
                    this.syncContext.update(newValue);
                }
            },
        },
    },
    methods: {
        syncValue(updatedValue) {
            if (this.syncContext.sync) {
                this.syncContext.update(updatedValue);
            }
        }
    }
}
