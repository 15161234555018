<template>
  <settings-container title="Certificate Settings"
                      :settings="settings"
                      :settings-key="settingsKey"
                      :has-changed="hasChanged"
                      @revert="$emit('revert')"
                      @updated="$emit('updated')">
    <!-- General -->
    <b-card no-body class="mb-0">
      <options-header v-b-toggle.options-certificate v-b-hover="hoverCertificate" header-class="options-header" title="Certificate"></options-header>
      <b-collapse id="options-certificate" role="tabpanel" class="options-collapse">
        <b-card-body body-bg-variant="light-primary">

          <!-- Border Options -->
          <options-header v-b-toggle.options-certificate-border header-class="options-header" title="Border"></options-header>
          <b-collapse id="options-certificate-border" role="tabpanel" class="options-collapse">
            <b-card-body body-bg-variant="white">
              <option-color v-model="settings.certificate.style.borderColor" label="Border Color" input-id="certificate-border-color"/>
              <option-value-unit v-model="settings.certificate.style.borderWidth" label="Border Width" input-id="certificate-border-width" placeholder="8px"/>
              <option-select v-model="settings.certificate.style.borderStyle" :options="options.borderStyle" label="Border Style" input-id="certificate-border-style" placeholder="solid"/>
            </b-card-body>
          </b-collapse>

          <!-- Margin Options -->
          <options-header v-b-toggle.options-certificate-margin header-class="options-header" title="Margin"></options-header>
          <b-collapse id="options-certificate-margin" role="tabpanel" class="options-collapse">
            <b-card-body body-bg-variant="white">
              <option-value-unit v-model="settings.certificate.style.margin" label="Margin" input-id="general-margin" placeholder="0.25in" @input="calculateCertificate"/>
            </b-card-body>
          </b-collapse>

          <!-- Padding Options -->
          <options-header v-b-toggle.options-certificate-padding header-class="options-header" title="Padding"></options-header>
          <b-collapse id="options-certificate-padding" role="tabpanel" class="options-collapse">
            <b-card-body body-bg-variant="white">
              <options-sync-container>
                <option-value-unit v-model="settings.certificate.style.paddingTop" label="Top" input-id="certificate-padding-top" placeholder="unset"/>
                <option-value-unit v-model="settings.certificate.style.paddingBottom" label="Bottom" input-id="certificate-padding-bottom" placeholder="unset"/>
                <option-value-unit v-model="settings.certificate.style.paddingLeft" label="Left" input-id="certificate-padding-left" placeholder="unset"/>
                <option-value-unit v-model="settings.certificate.style.paddingRight" label="Right" input-id="certificate-padding-right" placeholder="unset"/>
              </options-sync-container>
            </b-card-body>
          </b-collapse>

        </b-card-body>
      </b-collapse>
    </b-card>
    <!-- Body -->
    <b-card no-body class="mb-0">
      <options-header v-b-toggle.options-body v-b-hover="hoverBody" header-class="options-header" title="Body"></options-header>
      <b-collapse id="options-body" v-b-hover="hoverBody" role="tabpanel" class="options-collapse">
        <b-card-body body-bg-variant="light-primary">

          <!-- Font Options -->
          <options-header v-b-toggle.options-body-font header-class="options-header" title="Font"></options-header>
          <b-collapse id="options-body-font" role="tabpanel" class="options-collapse">
            <b-card-body body-bg-variant="white">
              <option-color v-model="settings.body.style.color" label="Font Color" input-id="body-color"/>
              <option-select v-model="settings.body.style.fontFamily" label="Font Family" input-id="body-font-family" :options="options.fontFamily" placeholder="Montserrat, Helvetica, Arial, serif"/>
              <option-select v-model="settings.body.style.fontWeight" label="Font Weight" input-id="body-font-weight" :options="options.fontWeight" placeholder="unset"/>
            </b-card-body>
          </b-collapse>

          <!-- Border Options -->
          <options-header v-b-toggle.options-body-border header-class="options-header" title="Border"></options-header>
          <b-collapse id="options-body-border" role="tabpanel" class="options-collapse">
            <b-card-body body-bg-variant="white">
              <option-color v-model="settings.body.style.borderColor" label="Border Color" input-id="body-border-color" />
              <option-value-unit v-model="settings.body.style.borderWidth" label="Border Width" input-id="body-border-width" placeholder="3px"/>
              <option-select v-model="settings.body.style.borderStyle" :options="options.borderStyle" label="Border Style" input-id="body-border-style" placeholder="solid"/>
            </b-card-body>
          </b-collapse>

          <!-- Padding Options -->
          <options-header v-b-toggle.options-body-padding header-class="options-header" title="Padding"></options-header>
          <b-collapse id="options-body-padding" role="tabpanel" class="options-collapse">
            <b-card-body body-bg-variant="white">
              <options-sync-container>
                <option-value-unit v-model="settings.body.style.paddingTop" label="Top" input-id="body-padding-top" placeholder="unset"/>
                <option-value-unit v-model="settings.body.style.paddingBottom" label="Bottom" input-id="body-padding-bottom" placeholder="unset"/>
                <option-value-unit v-model="settings.body.style.paddingLeft" label="Left" input-id="body-padding-left" placeholder="unset"/>
                <option-value-unit v-model="settings.body.style.paddingRight" label="Right" input-id="body-padding-right" placeholder="unset"/>
              </options-sync-container>
            </b-card-body>
          </b-collapse>

        </b-card-body>
      </b-collapse>
    </b-card>
    <!-- Logo -->
    <b-card no-body class="mb-0">
      <options-header v-b-toggle.options-logo v-b-hover="hoverLogo" header-class="options-header" title="Logo"></options-header>
      <b-collapse id="options-logo" role="tabpanel" class="options-collapse">
        <b-card-body body-bg-variant="light-primary">

          <!-- Image Options -->
          <options-header v-b-toggle.options-logo-image header-class="options-header" title="Image"></options-header>
          <b-collapse id="options-logo-image" role="tabpanel" class="options-collapse">
            <b-card-body body-bg-variant="white">
              <option-image v-model="settings.logo.src" label="Url" input-id="logo-src"/>
              <option-select v-model="settings.logo.style.objectFit" :options="options.fit" label="Fit" input-id="logo-fit" placeholder="cover"/>
            </b-card-body>
          </b-collapse>

          <!-- Border Options -->
          <options-header v-b-toggle.options-logo-border header-class="options-header" title="Border"></options-header>
          <b-collapse id="options-logo-border" role="tabpanel" class="options-collapse">
            <b-card-body body-bg-variant="white">
              <option-color v-model="settings.logo.style.borderColor" label="Border Color" input-id="logo-border-color" placeholder="unset"/>
              <option-value-unit v-model="settings.logo.style.borderWidth" label="Border Width" input-id="logo-border-width" placeholder="unset"/>
              <option-select v-model="settings.logo.style.borderStyle" :options="options.borderStyle" label="Border Style" input-id="logo-border-style" placeholder="unset"/>
              <option-select v-model="settings.logo.style.borderRadius" :options="options.radius" label="Border Radius" input-id="logo-radius" placeholder="round"/>
            </b-card-body>
          </b-collapse>

          <!-- Dimensions Options -->
          <options-header v-b-toggle.options-logo-dimensions header-class="options-header" title="Dimensions"></options-header>
          <b-collapse id="options-logo-dimensions" role="tabpanel" class="options-collapse">
            <b-card-body body-bg-variant="white">
              <options-sync-container>
                <option-value-unit v-model="settings.logo.style.width" label="Width" input-id="logo-width" placeholder="1.5in"/>
                <option-value-unit v-model="settings.logo.style.height" label="Height" input-id="logo-height" placeholder="1.5in"/>
              </options-sync-container>
            </b-card-body>
          </b-collapse>

          <!-- Margin Options -->
          <options-header v-b-toggle.options-logo-margin header-class="options-header" title="Margin"></options-header>
          <b-collapse id="options-logo-margin" role="tabpanel" class="options-collapse">
            <b-card-body body-bg-variant="white">
              <options-sync-container>
                <option-value-unit v-model="settings.logo.style.marginTop" label="Top" input-id="logo-margin-top" placeholder="1rem"/>
                <option-value-unit v-model="settings.logo.style.marginBottom" label="Bottom" input-id="logo-margin-bottom" placeholder="1rem"/>
              </options-sync-container>
            </b-card-body>
          </b-collapse>


        </b-card-body>
      </b-collapse>
    </b-card>
    <!-- Title -->
    <b-card no-body class="mb-0">
      <options-header v-b-toggle.options-title v-b-hover="hoverTitle" header-class="options-header" title="Title"></options-header>
      <b-collapse id="options-title" role="tabpanel" class="options-collapse">
        <b-card-body body-bg-variant="light-primary">

          <!-- Text Input -->
          <options-header v-b-toggle.options-title-text header-class="options-header" title="Text"></options-header>
          <b-collapse id="options-title-text" role="tabpanel" class="options-collapse">
            <b-card-body body-bg-variant="white">
              <b-textarea v-model="settings.title.text" no-resize rows="2" max-rows="2" class="font-small-3"/>
            </b-card-body>
          </b-collapse>

          <!-- Font Options -->
          <options-header v-b-toggle.options-title-font header-class="options-header" title="Font"></options-header>
          <b-collapse id="options-title-font" role="tabpanel" class="options-collapse">
            <b-card-body body-bg-variant="white">
              <option-value-unit v-model="settings.title.style.fontSize" label="Font Size" input-id="title-font-size" placeholder="2rem"/>
              <option-select v-model="settings.title.style.fontFamily" label="Font Family" input-id="title-font-family" :options="options.fontFamily" placeholder="Montserrat, Helvetica, Arial, serif"/>
              <option-select v-model="settings.title.style.fontWeight" label="Font Weight" input-id="title-font-weight" :options="options.fontWeight" placeholder="unset"/>
            </b-card-body>
          </b-collapse>

          <!-- Padding Options -->
          <options-header v-b-toggle.options-title-padding header-class="options-header" title="Padding"></options-header>
          <b-collapse id="options-title-padding" role="tabpanel" class="options-collapse">
            <b-card-body body-bg-variant="white">
              <options-sync-container>
                <option-value-unit v-model="settings.title.style.paddingTop" label="Top" input-id="title-padding-top" placeholder="unset"/>
                <option-value-unit v-model="settings.title.style.paddingBottom" label="Bottom" input-id="title-padding-bottom" placeholder="unset"/>
                <option-value-unit v-model="settings.title.style.paddingLeft" label="Left" input-id="title-padding-left" placeholder="unset"/>
                <option-value-unit v-model="settings.title.style.paddingRight" label="Right" input-id="title-padding-right" placeholder="unset"/>
              </options-sync-container>
            </b-card-body>
          </b-collapse>

          <!-- Margin Options -->
          <options-header v-b-toggle.options-title-margin header-class="options-header" title="Margin"></options-header>
          <b-collapse id="options-title-margin" role="tabpanel" class="options-collapse">
            <b-card-body body-bg-variant="white">
              <options-sync-container>
                <option-value-unit v-model="settings.title.style.marginTop" label="Top" input-id="title-margin-top" placeholder="1rem"/>
                <option-value-unit v-model="settings.title.style.marginBottom" label="Bottom" input-id="title-margin-bottom" placeholder="1rem"/>
                <option-value-unit v-model="settings.title.style.marginLeft" label="Left" input-id="title-margin-left" placeholder="1rem"/>
                <option-value-unit v-model="settings.title.style.marginRight" label="Right" input-id="title-margin-right" placeholder="1rem"/>
              </options-sync-container>
            </b-card-body>
          </b-collapse>
        </b-card-body>
      </b-collapse>
    </b-card>
    <!-- Awarded To -->
    <b-card no-body class="mb-0">
      <options-header v-b-toggle.options-awarded-to v-b-hover="hoverAwardedTo" header-class="options-header" title="Awarded To"></options-header>
      <b-collapse id="options-awarded-to" role="tabpanel" class="options-collapse">
        <b-card-body body-bg-variant="light-primary">

          <!-- Text -->
          <options-header v-b-toggle.options-awarded-to-text header-class="options-header" title="Text"></options-header>
          <b-collapse id="options-awarded-to-text" role="tabpanel" class="options-collapse">
            <b-card-body body-bg-variant="white">
              <b-textarea v-model="settings.awardedTo.text" no-resize rows="2" max-rows="2" class="font-small-3"/>
            </b-card-body>
          </b-collapse>

          <!-- Font Options -->
          <options-header v-b-toggle.options-awarded-to-font header-class="options-header" title="Font"></options-header>
          <b-collapse id="options-awarded-to-font" role="tabpanel" class="options-collapse">
            <b-card-body body-bg-variant="white">
              <option-value-unit v-model="settings.awardedTo.style.fontSize" label="Font Size" input-id="awarded-to-font-size" placeholder="1rem"/>
              <option-select v-model="settings.awardedTo.style.fontFamily" label="Font Family" input-id="awarded-to-font-family" :options="options.fontFamily" placeholder="Montserrat, Helvetica, Arial, serif"/>
              <option-select v-model="settings.awardedTo.style.fontWeight" label="Font Weight" input-id="awarded-to-font-weight" :options="options.fontWeight" placeholder="unset"/>
            </b-card-body>
          </b-collapse>

          <!-- Padding Options -->
          <options-header v-b-toggle.options-awarded-to-padding header-class="options-header" title="Padding"></options-header>
          <b-collapse id="options-awarded-to-padding" role="tabpanel" class="options-collapse">
            <b-card-body body-bg-variant="white">
              <options-sync-container>
                <option-value-unit v-model="settings.awardedTo.style.paddingTop" label="Top" input-id="awarded-to-padding-top" placeholder="unset"/>
                <option-value-unit v-model="settings.awardedTo.style.paddingBottom" label="Bottom" input-id="awarded-to-padding-bottom" placeholder="unset"/>
                <option-value-unit v-model="settings.awardedTo.style.paddingLeft" label="Left" input-id="awarded-to-padding-left" placeholder="unset"/>
                <option-value-unit v-model="settings.awardedTo.style.paddingRight" label="Right" input-id="awarded-to-padding-right" placeholder="unset"/>
              </options-sync-container>
            </b-card-body>
          </b-collapse>

          <!-- Margin Options -->
          <options-header v-b-toggle.options-awarded-to-margin header-class="options-header" title="Margin"></options-header>
          <b-collapse id="options-awarded-to-margin" role="tabpanel" class="options-collapse">
            <b-card-body body-bg-variant="white">
              <options-sync-container>
                <option-value-unit v-model="settings.awardedTo.style.marginTop" label="Top" input-id="awarded-to-margin-top" placeholder="1rem"/>
                <option-value-unit v-model="settings.awardedTo.style.marginBottom" label="Bottom" input-id="awarded-to-margin-bottom" placeholder="1rem"/>
                <option-value-unit v-model="settings.awardedTo.style.marginLeft" label="Left" input-id="awarded-to-margin-left" placeholder="1rem"/>
                <option-value-unit v-model="settings.awardedTo.style.marginRight" label="Right" input-id="awarded-to-margin-right" placeholder="1rem"/>
              </options-sync-container>
            </b-card-body>
          </b-collapse>

        </b-card-body>
      </b-collapse>
    </b-card>
    <!-- Student -->
    <b-card no-body class="mb-0">
      <options-header v-b-toggle.options-student v-b-hover="hoverStudent" header-class="options-header" title="Student"></options-header>
      <b-collapse id="options-student" role="tabpanel" class="options-collapse">
        <b-card-body body-bg-variant="light-primary">
          <!-- Font Options -->
          <options-header v-b-toggle.options-student-font header-class="options-header" title="Font"></options-header>
          <b-collapse id="options-student-font" role="tabpanel" class="options-collapse">
            <b-card-body body-bg-variant="white">
              <option-value-unit v-model="settings.student.style.fontSize" label="Font Size" input-id="student-font-size" placeholder="2.5rem"/>
              <option-select v-model="settings.student.style.fontFamily" label="Font Family" input-id="student-font-family" :options="options.fontFamily" placeholder="Montserrat, Helvetica, Arial, serif"/>
              <option-select v-model="settings.student.style.fontWeight" label="Font Weight" input-id="student-font-weight" :options="options.fontWeight" placeholder="unset"/>
            </b-card-body>
          </b-collapse>

          <!-- Padding Options -->
          <options-header v-b-toggle.options-student-padding header-class="options-header" title="Padding"></options-header>
          <b-collapse id="options-student-padding" role="tabpanel" class="options-collapse">
            <b-card-body body-bg-variant="white">
              <options-sync-container>
                <option-value-unit v-model="settings.student.style.paddingTop" label="Top" input-id="student-padding-top" placeholder="unset"/>
                <option-value-unit v-model="settings.student.style.paddingBottom" label="Bottom" input-id="student-padding-bottom" placeholder="unset"/>
                <option-value-unit v-model="settings.student.style.paddingLeft" label="Left" input-id="student-padding-left" placeholder="unset"/>
                <option-value-unit v-model="settings.student.style.paddingRight" label="Right" input-id="student-padding-right" placeholder="unset"/>
              </options-sync-container>
            </b-card-body>
          </b-collapse>

          <!-- Margin Options -->
          <options-header v-b-toggle.options-student-margin header-class="options-header" title="Margin"></options-header>
          <b-collapse id="options-student-margin" role="tabpanel" class="options-collapse">
            <b-card-body body-bg-variant="white">
              <options-sync-container>
                <option-value-unit v-model="settings.student.style.marginTop" label="Top" input-id="student-margin-top" placeholder="1rem"/>
                <option-value-unit v-model="settings.student.style.marginBottom" label="Bottom" input-id="student-margin-bottom" placeholder="1rem"/>
                <option-value-unit v-model="settings.student.style.marginLeft" label="Left" input-id="student-margin-left" placeholder="1rem"/>
                <option-value-unit v-model="settings.student.style.marginRight" label="Right" input-id="student-margin-right" placeholder="1rem"/>
              </options-sync-container>
            </b-card-body>
          </b-collapse>

        </b-card-body>
      </b-collapse>
    </b-card>
    <!-- School -->
    <b-card no-body class="mb-0">
      <options-header v-b-toggle.options-school v-b-hover="hoverSchool" header-class="options-header" title="School"></options-header>
      <b-collapse id="options-school" role="tabpanel" class="options-collapse">
        <b-card-body body-bg-variant="light-primary">
          <!-- Text Options -->
          <options-header v-b-toggle.options-school-text header-class="options-header" title="Text"></options-header>
          <b-collapse id="options-school-text" role="tabpanel" class="options-collapse">
            <b-card-body body-bg-variant="white">
              <option-select v-model="settings.school.textFormat"
                             :options="[
                                        { value: 'name.legal', label: 'Legal Name' },
                                        { value: 'name.popular', label: 'Popular Name' }
                                     ]"
                             label="Text Format" input-id="school-align" placeholder="Legal Name"/>
            </b-card-body>
          </b-collapse>

          <!-- Font Options -->
          <options-header v-b-toggle.options-school-font header-class="options-header" title="Font"></options-header>
          <b-collapse id="options-school-font" role="tabpanel" class="options-collapse">
            <b-card-body body-bg-variant="white">
              <option-value-unit v-model="settings.school.style.fontSize" label="Font Size" input-id="school-font-size" placeholder="1.5rem"/>
              <option-select v-model="settings.school.style.fontFamily" label="Font Family" input-id="school-font-family" :options="options.fontFamily" placeholder="Montserrat, Helvetica, Arial, serif"/>
              <option-select v-model="settings.school.style.fontWeight" label="Font Weight" input-id="school-font-weight" :options="options.fontWeight" placeholder="unset"/>
            </b-card-body>
          </b-collapse>

          <!-- Padding Options -->
          <options-header v-b-toggle.options-school-padding header-class="options-header" title="Padding"></options-header>
          <b-collapse id="options-school-padding" role="tabpanel" class="options-collapse">
            <b-card-body body-bg-variant="white">
              <options-sync-container>
                <option-value-unit v-model="settings.school.style.paddingTop" label="Top" input-id="school-padding-top" placeholder="unset"/>
                <option-value-unit v-model="settings.school.style.paddingBottom" label="Bottom" input-id="school-padding-bottom" placeholder="unset"/>
                <option-value-unit v-model="settings.school.style.paddingLeft" label="Left" input-id="school-padding-left" placeholder="unset"/>
                <option-value-unit v-model="settings.school.style.paddingRight" label="Right" input-id="school-padding-right" placeholder="unset"/>
              </options-sync-container>
            </b-card-body>
          </b-collapse>

          <!-- Margin Options -->
          <options-header v-b-toggle.options-school-margin header-class="options-header" title="Margin"></options-header>
          <b-collapse id="options-school-margin" role="tabpanel" class="options-collapse">
            <b-card-body body-bg-variant="white">
              <options-sync-container>
                <option-value-unit v-model="settings.school.style.marginTop" label="Top" input-id="school-margin-top" placeholder="1rem"/>
                <option-value-unit v-model="settings.school.style.marginBottom" label="Bottom" input-id="school-margin-bottom" placeholder="1rem"/>
                <option-value-unit v-model="settings.school.style.marginLeft" label="Left" input-id="school-margin-left" placeholder="1rem"/>
                <option-value-unit v-model="settings.school.style.marginRight" label="Right" input-id="school-margin-right" placeholder="1rem"/>
              </options-sync-container>
            </b-card-body>
          </b-collapse>

        </b-card-body>
      </b-collapse>
    </b-card>
    <!-- Recognition -->
    <b-card no-body class="mb-0">
      <options-header v-b-toggle.options-recognition v-b-hover="hoverRecognition" header-class="options-header" title="Recognition"></options-header>
      <b-collapse id="options-recognition" role="tabpanel" class="options-collapse">
        <b-card-body body-bg-variant="light-primary">
          <!-- Text Options -->
          <options-header v-b-toggle.options-recognition-text header-class="options-header" title="Text"></options-header>
          <b-collapse id="options-recognition-text" role="tabpanel" class="options-collapse">
            <b-textarea v-model="settings.recognition.text" no-resize rows="2" max-rows="2" class="font-small-3"/>
          </b-collapse>

          <!-- Font Options -->
          <options-header v-b-toggle.options-recognition-font header-class="options-header" title="Font"></options-header>
          <b-collapse id="options-recognition-font" role="tabpanel" class="options-collapse">
            <b-card-body body-bg-variant="white">
              <option-value-unit v-model="settings.recognition.style.fontSize" label="Font Size" input-id="recognition-font-size" placeholder="1.4rem"/>
              <option-select v-model="settings.recognition.style.fontFamily" label="Font Family" input-id="recognition-font-family" :options="options.fontFamily" placeholder="Montserrat, Helvetica, Arial, serif"/>
              <option-select v-model="settings.recognition.style.fontWeight" label="Font Weight" input-id="recognition-font-weight" :options="options.fontWeight" placeholder="unset"/>
            </b-card-body>
          </b-collapse>

          <!-- Padding Options -->
          <options-header v-b-toggle.options-recognition-padding header-class="options-header" title="Padding"></options-header>
          <b-collapse id="options-recognition-padding" role="tabpanel" class="options-collapse">
            <b-card-body body-bg-variant="white">
              <options-sync-container>
                <option-value-unit v-model="settings.recognition.style.paddingTop" label="Top" input-id="recognition-padding-top" placeholder="unset"/>
                <option-value-unit v-model="settings.recognition.style.paddingBottom" label="Bottom" input-id="recognition-padding-bottom" placeholder="unset"/>
                <option-value-unit v-model="settings.recognition.style.paddingLeft" label="Left" input-id="recognition-padding-left" placeholder="25%"/>
                <option-value-unit v-model="settings.recognition.style.paddingRight" label="Right" input-id="recognition-padding-right" placeholder="25%"/>
              </options-sync-container>
            </b-card-body>
          </b-collapse>

          <!-- Margin Options -->
          <options-header v-b-toggle.options-recognition-margin header-class="options-header" title="Margin"></options-header>
          <b-collapse id="options-recognition-margin" role="tabpanel" class="options-collapse">
            <b-card-body body-bg-variant="white">
              <options-sync-container>
                <option-value-unit v-model="settings.recognition.style.marginTop" label="Top" input-id="recognition-margin-top" placeholder="1rem"/>
                <option-value-unit v-model="settings.recognition.style.marginBottom" label="Bottom" input-id="recognition-margin-bottom" placeholder="1rem"/>
                <option-value-unit v-model="settings.recognition.style.marginLeft" label="Left" input-id="recognition-margin-left" placeholder="1rem"/>
                <option-value-unit v-model="settings.recognition.style.marginRight" label="Right" input-id="recognition-margin-right" placeholder="1rem"/>
              </options-sync-container>
            </b-card-body>
          </b-collapse>

        </b-card-body>
      </b-collapse>
    </b-card>
    <!-- Ensemble -->
    <b-card no-body class="mb-0">
      <options-header v-b-toggle.options-ensemble v-b-hover="hoverEnsemble" header-class="options-header" title="Ensemble"></options-header>
      <b-collapse id="options-ensemble" role="tabpanel" class="options-collapse">
        <b-card-body body-bg-variant="light-primary">
          <!-- Font Options -->
          <options-header v-b-toggle.options-ensemble-font header-class="options-header" title="Font"></options-header>
          <b-collapse id="options-ensemble-font" role="tabpanel" class="options-collapse">
            <b-card-body body-bg-variant="white">
              <option-value-unit v-model="settings.ensemble.style.fontSize" label="Font Size" input-id="ensemble-font-size" placeholder="1.75rem"/>
              <option-select v-model="settings.ensemble.style.fontFamily" label="Font Family" input-id="ensemble-font-family" :options="options.fontFamily" placeholder="Montserrat, Helvetica, Arial, serif"/>
              <option-select v-model="settings.ensemble.style.fontWeight" label="Font Weight" input-id="ensemble-font-weight" :options="options.fontWeight" placeholder="unset"/>
            </b-card-body>
          </b-collapse>

          <!-- Padding Options -->
          <options-header v-b-toggle.options-ensemble-padding header-class="options-header" title="Padding"></options-header>
          <b-collapse id="options-ensemble-padding" role="tabpanel" class="options-collapse">
            <b-card-body body-bg-variant="white">
              <options-sync-container>
                <option-value-unit v-model="settings.ensemble.style.paddingTop" label="Top" input-id="ensemble-padding-top" placeholder="unset"/>
                <option-value-unit v-model="settings.ensemble.style.paddingBottom" label="Bottom" input-id="ensemble-padding-bottom" placeholder="unset"/>
                <option-value-unit v-model="settings.ensemble.style.paddingLeft" label="Left" input-id="ensemble-padding-left" placeholder="unset"/>
                <option-value-unit v-model="settings.ensemble.style.paddingRight" label="Right" input-id="ensemble-padding-right" placeholder="unset"/>
              </options-sync-container>
            </b-card-body>
          </b-collapse>

          <!-- Margin Options -->
          <options-header v-b-toggle.options-ensemble-margin header-class="options-header" title="Margin"></options-header>
          <b-collapse id="options-ensemble-margin" role="tabpanel" class="options-collapse">
            <b-card-body body-bg-variant="white">
              <options-sync-container>
                <option-value-unit v-model="settings.ensemble.style.marginTop" label="Top" input-id="ensemble-margin-top" placeholder="1rem"/>
                <option-value-unit v-model="settings.ensemble.style.marginBottom" label="Bottom" input-id="ensemble-margin-bottom" placeholder="1rem"/>
                <option-value-unit v-model="settings.ensemble.style.marginLeft" label="Left" input-id="ensemble-margin-left" placeholder="1rem"/>
                <option-value-unit v-model="settings.ensemble.style.marginRight" label="Right" input-id="ensemble-margin-right" placeholder="1rem"/>
              </options-sync-container>
            </b-card-body>
          </b-collapse>

        </b-card-body>
      </b-collapse>
    </b-card>
    <!-- Footer -->
    <b-card no-body class="mb-0">
      <options-header v-b-toggle.options-footer v-b-hover="hoverFooter" header-class="options-header" title="Footer"></options-header>
      <b-collapse id="options-footer" role="tabpanel" class="options-collapse">
        <b-card-body body-bg-variant="light-primary">

          <!-- Layout Options -->
          <options-header v-b-toggle.options-footer-layout header-class="options-header" title="Layout"></options-header>
          <b-collapse id="options-footer-layout" role="tabpanel" class="options-collapse">
            <b-card-body body-bg-variant="white">
              <option-select v-model="settings.footer.style.display" :options="options.display" label="Display" input-id="footer-display" placeholder="flex"/>
              <option-select v-model="settings.footer.style.justifyContent" :options="options.justify" label="Justify" input-id="footer-justify" placeholder="space between"/>
              <option-select v-model="settings.footer.style.alignItems" label="Vertical Alignment" input-id="footer-right-line1-weight" :options="options.align" placeholder="center"/>
            </b-card-body>
          </b-collapse>

          <!-- Padding Options -->
          <options-header v-b-toggle.options-footer-padding header-class="options-header" title="Padding"></options-header>
          <b-collapse id="options-footer-padding" role="tabpanel" class="options-collapse">
            <b-card-body body-bg-variant="white">
              <options-sync-container>
                <option-value-unit v-model="settings.footer.style.paddingTop" label="Top" input-id="footer-padding-top" placeholder="1rem"/>
                <option-value-unit v-model="settings.footer.style.paddingBottom" label="Bottom" input-id="footer-padding-bottom" placeholder="1rem"/>
                <option-value-unit v-model="settings.footer.style.paddingLeft" label="Left" input-id="footer-padding-left" placeholder="1rem"/>
                <option-value-unit v-model="settings.footer.style.paddingRight" label="Right" input-id="footer-padding-right" placeholder="1rem"/>
              </options-sync-container>
            </b-card-body>
          </b-collapse>

          <!-- Margin Options -->
          <options-header v-b-toggle.options-footer-margin header-class="options-header" title="Margin"></options-header>
          <b-collapse id="options-footer-margin" role="tabpanel" class="options-collapse">
            <b-card-body body-bg-variant="white">
              <options-sync-container>
                <option-value-unit v-model="settings.footer.style.marginBottom" label="Bottom" input-id="footer-margin-bottom" placeholder="unset"/>
                <option-value-unit v-model="settings.footer.style.marginLeft" label="Left" input-id="footer-margin-left" placeholder="unset"/>
                <option-value-unit v-model="settings.footer.style.marginRight" label="Right" input-id="footer-margin-right" placeholder="unset"/>
              </options-sync-container>
            </b-card-body>
          </b-collapse>

          <hr class="options-divider">

          <!-- Left Options -->
          <options-header v-b-toggle.options-footer-left v-b-hover="hoverFooterLeft" header-class="options-header" title="Footer Left"></options-header>
          <b-collapse id="options-footer-left" role="tabpanel" class="options-collapse">
            <b-card-body body-bg-variant="white" class="p-50">
              <!-- Text Options -->
              <options-header v-b-toggle.options-footer-left-text header-class="options-header" title="Text"></options-header>
              <b-collapse id="options-footer-left-text" role="tabpanel" class="options-collapse">
                <b-card-body body-bg-variant="light-primary">

                  <!-- Line 1 -->
                  <options-header v-b-toggle.options-footer-left-line1 header-class="options-header" title="Line 1"></options-header>
                  <b-collapse id="options-footer-left-line1" role="tabpanel" class="options-collapse">
                    <b-card-body body-bg-variant="white">
                      <b-form-group label="Text" label-for="footer-left-line1-text" label-class="font-weight-bold font-small-3">
                        <b-form-input id="footer-left-line1-text" v-model="settings.footer.left.line1.text" />
                      </b-form-group>
                      <options-header v-b-toggle.options-footer-left-line1-font header-class="options-header" title="Font"></options-header>
                      <b-collapse id="options-footer-left-line1-font" role="tabpanel" class="options-collapse">
                        <b-card-body body-bg-variant="white">
                          <option-value-unit v-model="settings.footer.left.line1.style.fontSize" label="Font Size" input-id="footer-left-line1-font-size" placeholder="2rem"/>
                          <option-select v-model="settings.footer.left.line1.style.fontFamily" label="Font Family" input-id="footer-left-line1-font-family" :options="options.fontFamily" placeholder="Montserrat"/>
                          <option-select v-model="settings.footer.left.line1.style.fontWeight" label="Font Weight" input-id="footer-left-line1-weight" :options="options.fontWeight" placeholder="unset"/>
                        </b-card-body>
                      </b-collapse>
                    </b-card-body>
                  </b-collapse>

                  <!-- Line 2 -->
                  <options-header v-b-toggle.options-footer-left-line2 header-class="options-header" title="Line 2"></options-header>
                  <b-collapse id="options-footer-left-line2" role="tabpanel" class="options-collapse">
                    <b-card-body body-bg-variant="white">
                      <b-form-group label="Text" label-for="footer-left-line2-text" label-class="font-weight-bold font-small-3">
                        <b-form-input id="footer-left-line1-text" v-model="settings.footer.left.line2.text" />
                      </b-form-group>
                      <options-header v-b-toggle.options-footer-left-line2-font header-class="options-header" title="Font"></options-header>
                      <b-collapse id="options-footer-left-line2-font" role="tabpanel" class="options-collapse">
                        <b-card-body body-bg-variant="white">
                          <option-value-unit v-model="settings.footer.left.line2.style.fontSize" label="Font Size" input-id="footer-left-line2-font-size" placeholder="2rem"/>
                          <option-select v-model="settings.footer.left.line2.style.fontFamily" label="Font Family" input-id="footer-left-line2-font-family" :options="options.fontFamily" placeholder="Montserrat"/>
                          <option-select v-model="settings.footer.left.line2.style.fontWeight" label="Font Weight" input-id="footer-left-line2-font-weight" :options="options.fontWeight" placeholder="unset"/>
                        </b-card-body>
                      </b-collapse>
                    </b-card-body>
                  </b-collapse>
                </b-card-body>
              </b-collapse>

              <!-- Margin Options -->
              <options-header v-b-toggle.options-footer-left-margin header-class="options-header" title="Margin"></options-header>
              <b-collapse id="options-footer-left-margin" role="tabpanel" class="options-collapse">
                <b-card-body body-bg-variant="white">
                  <options-sync-container>
                    <option-value-unit v-model="settings.footer.left.style.marginLeft" label="Margin Left" input-id="footer-left-margin-left" placeholder="2rem"/>
                    <option-value-unit v-model="settings.footer.left.style.marginRight" label="Margin Right" input-id="footer-left-margin-right" placeholder="2rem"/>
                  </options-sync-container>
                </b-card-body>
              </b-collapse>

            </b-card-body>
          </b-collapse>

          <!-- Right Options -->
          <options-header v-b-toggle.options-footer-right v-b-hover="hoverFooterRight" header-class="options-header" title="Footer Right"></options-header>
          <b-collapse id="options-footer-right" role="tabpanel" class="options-collapse">
            <b-card-body body-bg-variant="white" class="p-50">
              <!-- Text Options -->
              <options-header v-b-toggle.options-footer-right-text header-class="options-header" title="Text"></options-header>
              <b-collapse id="options-footer-right-text" role="tabpanel" class="options-collapse">
                <b-card-body body-bg-variant="light-primary">

                  <!-- Line 1 -->
                  <options-header v-b-toggle.options-footer-right-line1 header-class="options-header" title="Line 1"></options-header>
                  <b-collapse id="options-footer-right-line1" role="tabpanel" class="options-collapse">
                    <b-card-body body-bg-variant="white">
                      <b-form-group label="Text" label-for="footer-right-line1-text" label-class="font-weight-bold font-small-3">
                        <b-form-input id="footer-right-line1-text" v-model="settings.footer.right.line1.text" />
                      </b-form-group>
                      <options-header v-b-toggle.options-footer-right-line1-font header-class="options-header" title="Font"></options-header>
                      <b-collapse id="options-footer-right-line1-font" role="tabpanel" class="options-collapse">
                        <b-card-body body-bg-variant="white">
                          <!--                                  <option-color v-model="settings.footer.right.line1.style.color" label="Font Color" input-id="footer-right-line1-color"/>-->
                          <option-value-unit v-model="settings.footer.right.line1.style.fontSize" label="Font Size" input-id="footer-right-line1-font-size" placeholder="2rem"/>
                          <option-select v-model="settings.footer.right.line1.style.fontFamily" label="Font Family" input-id="footer-right-line1-font-family" :options="options.fontFamily" placeholder="Montserrat"/>
                          <option-select v-model="settings.footer.right.line1.style.fontWeight" label="Font Weight" input-id="footer-right-line1-weight" :options="options.fontWeight" placeholder="unset"/>
                        </b-card-body>
                      </b-collapse>
                    </b-card-body>
                  </b-collapse>

                  <!-- Line 2 -->
                  <options-header v-b-toggle.options-footer-right-line2 header-class="options-header" title="Line 2"></options-header>
                  <b-collapse id="options-footer-right-line2" role="tabpanel" class="options-collapse">
                    <b-card-body body-bg-variant="white">
                      <b-form-group label="Text" label-for="footer-right-line2-text" label-class="font-weight-bold font-small-3">
                        <b-form-input id="footer-right-line1-text" v-model="settings.footer.right.line2.text" />
                      </b-form-group>
                      <options-header v-b-toggle.options-footer-right-line2-font header-class="options-header" title="Font"></options-header>
                      <b-collapse id="options-footer-right-line2-font" role="tabpanel" class="options-collapse">
                        <b-card-body body-bg-variant="white">
                          <option-value-unit v-model="settings.footer.right.line2.style.fontSize" label="Font Size" input-id="footer-right-line2-font-size" placeholder="2rem"/>
                          <option-select v-model="settings.footer.right.line2.style.fontFamily" label="Font Family" input-id="footer-right-line2-font-family" :options="options.fontFamily" placeholder="Montserrat"/>
                          <option-select v-model="settings.footer.right.line2.style.fontWeight" label="Font Weight" input-id="footer-right-line2-font-weight" :options="options.fontWeight" placeholder="unset"/>
                        </b-card-body>
                      </b-collapse>
                    </b-card-body>
                  </b-collapse>
                </b-card-body>
              </b-collapse>

              <!-- Margin Options -->
              <options-header v-b-toggle.options-footer-right-margin header-class="options-header" title="Margin"></options-header>
              <b-collapse id="options-footer-right-margin" role="tabpanel" class="options-collapse">
                <b-card-body body-bg-variant="white">
                  <options-sync-container>
                    <option-value-unit v-model="settings.footer.right.style.marginLeft" label="Margin Left" input-id="footer-right-margin-left" placeholder="2rem"/>
                    <option-value-unit v-model="settings.footer.right.style.marginRight" label="Margin Right" input-id="footer-right-margin-right" placeholder="2rem"/>
                  </options-sync-container>
                </b-card-body>
              </b-collapse>

            </b-card-body>
          </b-collapse>
          <!-- Footer Nested Options -->

        </b-card-body>
      </b-collapse>
    </b-card>
  </settings-container>
</template>

<script>
import SettingsContainer from '@/views/printable/settings/SettingsContainer.vue';
import OptionImage from '@/views/printable/settings/OptionImage.vue';
import OptionValueUnit from '@/views/printable/settings/OptionValueUnit.vue';
import OptionsSyncContainer from '@/views/printable/settings/OptionsSyncContainer.vue';
import OptionsHeader from '@/views/printable/settings/OptionsHeader.vue';
import OptionColor from '@/views/printable/settings/OptionColor.vue';
import OptionSelect from '@/views/printable/settings/OptionSelect.vue';
import printableSettingsMixin from '@/views/printable/settings/settings.mixin';

export default {
  name: 'CertificateSettings',
  components: {
    OptionSelect,
    OptionColor,
    OptionsHeader,
    OptionsSyncContainer,
    OptionValueUnit,
    OptionImage,
    SettingsContainer
  },
  mixins: [ printableSettingsMixin ],
  props: {
    settings: {
      type: Object,
      required: true
    },
    settingsKey: {
      type: String,
      required: true
    },
    hasChanged: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    calculateCertificate() {
      if(this.settings.certificate.style.margin.value) {
        this.settings.certificate.style.width = `calc(11in - ${this.settings.certificate.style.margin.value * 2}${this.settings.certificate.style.margin.unit} )`
        this.settings.certificate.style.height = `calc(8.5in - ${this.settings.certificate.style.margin.value * 2}${this.settings.certificate.style.margin.unit} )`
      }
      else {
        this.settings.certificate.style.width = null
        this.settings.certificate.style.height = null
      }
    },

    /** Hover **/
    hoverCertificate(isHovered) {
      this.$emit('hover:certificate', isHovered)
    },
    hoverBody(isHovered) {
      this.$emit('hover:body', isHovered)
    },
    hoverLogo(isHovered) {
      this.$emit('hover:logo', isHovered)
    },
    hoverTitle(isHovered) {
      this.$emit('hover:title', isHovered)
    },
    hoverAwardedTo(isHovered) {
      this.$emit('hover:awardedTo', isHovered)
    },
    hoverStudent(isHovered) {
      this.$emit('hover:student', isHovered)
    },
    hoverSchool(isHovered) {
      this.$emit('hover:school', isHovered)
    },
    hoverRecognition(isHovered) {
      this.$emit('hover:recognition', isHovered)
    },
    hoverEnsemble(isHovered) {
      this.$emit('hover:ensemble', isHovered)
    },
    hoverFooter(isHovered) {
      this.$emit('hover:footer', isHovered)
    },
    hoverFooterLeft(isHovered) {
      this.$emit('hover:footer-left', isHovered)
    },
    hoverFooterRight(isHovered) {
      this.$emit('hover:footer-right', isHovered)
    },
  }
}
</script>

<style scoped>

</style>
