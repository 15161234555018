<template>
  <div class="option-sync-container">
    <slot></slot>
    <div class="option-sync-actions">
      <b-checkbox v-model="syncContext.sync" :disabled="disabled">
        <span class="font-small-3">Synchronize</span>
      </b-checkbox>
    </div>
  </div>
</template>

<script>
/**
 * OptionsSyncContainer.vue
 * Is a component that acts as a wrapper for multiple components that need to synchronize their
 * values when a specific condition is met (in this case, when a checkbox is checked). It provides the necessary context
 * for its child components to synchronize their values. The main parts of the OptionsSyncContainer component are:
 *
 * Template:
 * The template consists of a div with the option-sync-container class and a named slot, which is where the child
 * components will be placed. Additionally, it has a checkbox component for enabling or disabling the sync functionality.
 *
 * Data:
 * The data function creates an observable syncContext object that contains the sync property, an updatedValue property,
 * and an update function. The sync property determines if the synchronization is enabled or disabled, the updatedValue
 * holds the latest value that needs to be synced, and the update function is responsible for updating the values of the
 * child components.
 *
 * Provide function:
 * The provide function returns the syncContext object so that it can be injected into the child components that use
 * the option-sync-item.mixin.js mixin.
 *
 * In summary, the OptionsSyncContainer component acts as a wrapper for synchronizing values between its child components.
 * It provides a syncContext object that contains the necessary information and functions for syncing the values. The
 * child components, which utilize the option-sync-item.mixin.js mixin, can then access this context and update their
 * values accordingly when the sync functionality is enabled.
 */

import Vue from 'vue';

export default {
  name: 'OptionsSyncContainer',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  // The component's data contains a reactive object `syncContext` that stores the `sync` state and an `update` function
  data() {
    const syncContext = Vue.observable({
      sync: false,
      updatedValue: {
        value: null,
        unit: null
      },
      update: (newValue) => {
        if (syncContext.sync) {
          syncContext.updatedValue = newValue;
        }
      },
    });

    this.syncContext = syncContext;
    return {
      syncContext,
    };
  },
  // The component provides the `syncContext` to child components using the `provide` function
  provide() {
    return {
      syncContext: this.syncContext,
    };
  },
};
</script>

<style scoped>
  .option-sync-container {
    position: relative;
  }
  .option-sync-actions {
    background-color: #f6f6f6;
    padding: 1rem;
    border-radius: 0.357rem;
  }
</style>
