/**
 * settings.mixin.js
 * This mixin provides common functionality for managing settings using the AWS Amplify library and GraphQL operations.
 * It contains methods for fetching and updating settings, deep-merging objects, and filtering objects to remove
 * empty or null properties.
 *
 * Methods:
 * getSettings(key):
 * Fetches a setting object from the backend using the key parameter.
 * Returns the parsed JSON value if the setting exists, or null if it does not.
 *
 * updateSettings(key, value):
 * Updates a setting object in the backend with the specified key and value.
 * Serializes the filtered object and calls
 * the updateSetting mutation.
 * Notifies the user of success or failure with a notification.
 *
 * deepMerge(target, source):
 * Merges the source object into the target object, recursively combining nested properties.
 * Returns the target object with the merged properties.
 *
 * filterObject(obj):
 * Recursively filters the input object to remove empty or null properties.
 * Returns a new object with the filtered properties.
 *
 * In summary, the settings.mixin.js provides a way to manage settings by fetching and updating them
 * from the backend using GraphQL operations. It also includes utility functions for merging and filtering objects
 * to help with processing settings data.
 */

import {API, graphqlOperation} from 'aws-amplify';
import {getSetting} from '@/graphql/queries';
import {updateSetting} from '@/graphql/mutations';
import notify from '@/mixins/notify.mixin';
import _ from 'lodash';

export default {
    mixins: [ notify ],
    data: () => ({
        initialSettings: null,
        options: {
            align: [
                { value: 'start', label: 'Start' },
                { value: 'center', label: 'Center' },
                { value: 'end', label: 'End' },
            ],
            borderStyle: [
                { value: 'none', label: 'None'},
                { value: 'solid', label: 'Solid'},
                { value: 'dashed', label: 'Dashed'},
                { value: 'dotted', label: 'Dotted'},
                { value: 'double', label: 'Double'},
                { value: 'groove', label: 'Groove'},
                { value: 'ridge', label: 'Ridge'},
                { value: 'inset', label: 'Inset'},
                { value: 'outset', label: 'Outset'},
                { value: 'hidden', label: 'Hidden'},
            ],
            display: [
                { value: 'flex', label: 'Flex' },
                { value: 'block', label: 'Block' },
                { value: 'inline-block', label: 'Inline Block' },
                { value: 'inline', label: 'Inline' },
                { value: 'none', label: 'None' },
            ],
            fontFamily: [
                { value: 'Arial', label: 'Arial' },
                { value: 'Georgia', label: 'Georgia' },
                { value: 'Times New Roman', label: 'Times New Roman' },
                { value: 'Courier New', label: 'Courier New' },
                { value: 'Montserrat', label: 'Montserrat' },
                { value: 'Helvetica', label: 'Helvetica' },
                { value: 'serif', label: 'Serif' },
            ],
            fontWeight: [
                { value: '100', label: 'Thin' },
                { value: '200', label: 'Extra Light' },
                { value: '300', label: 'Light' },
                { value: '400', label: 'Normal' },
                { value: '500', label: 'Medium' },
                { value: '600', label: 'Semi Bold' },
                { value: '700', label: 'Bold' },
                { value: '800', label: 'Extra Bold' },
                { value: '900', label: 'Black' },
            ],
            justify: [
                { value: 'flex-start', label: 'Start' },
                { value: 'flex-end', label: 'End' },
                { value: 'center', label: 'Center' },
                { value: 'space-between', label: 'Space Between' },
                { value: 'space-around', label: 'Space Around' },
                { value: 'space-evenly', label: 'Space Evenly' },
            ],
            fit: [
                { value: 'fill', label: 'Fill' },
                { value: 'contain', label: 'Contain' },
                { value: 'cover', label: 'Cover' },
                { value: 'none', label: 'None' },
                { value: 'scale-down', label: 'Scale Down' },
            ],
            radius: [
                { value: '50%', label: 'Round' },
                { value: '0%', label: 'Square' }
            ],
        }
    }),
    computed: {
        hasChanges() {
            return JSON.stringify(this.initialSettings) !== JSON.stringify(this.settings)
        }
    },
    /*watch: {
        hasChanges(value, oldValue) {
            console.log('hasChanges', this.initialSettings.badge.style.color, this.settings.badge.style.color)
            console.log('hasChanges', value, oldValue)
        }
    },*/
    methods: {
        async getSettings(key) {
            const response = await API.graphql(graphqlOperation(getSetting, { key: key }));
            const data = response.data.getSetting
            if(data) {
                return JSON.parse(data.value)
            }
            return { }
        },
        async updateSettings(key, value) {
            const icon = 'fas fa-gear'
            try {
                const settingInput = {
                    key: key,
                    value: JSON.stringify(this.filterObject(value))
                }
                await API.graphql(graphqlOperation(updateSetting, { input: settingInput }));
                this.$emit('updated')
                this.notify({ title: 'Success', text: 'Setting was successfully updated', icon: icon, variant: 'success' });
            }
            catch(error) {
                console.error(error)
                this.notify({ title: 'Error', text: 'Setting failed to update', icon: icon, variant: 'danger'});
            }
        },
        syncInitialSettings() {
            this.initialSettings = _.cloneDeep(this.settings);
        },
        deepMerge(target, source) {
            Object.keys(source).forEach((key) => {
                if (source[key] !== null && typeof source[key] === 'object') {
                    if (!target[key]) {
                        target[key] = {};
                    }
                    this.deepMerge(target[key], source[key]);
                } else {
                    target[key] = source[key];
                }
            });
            return target;
        },
        filterObject(obj) {
            if (typeof obj !== 'object' || obj === null) {
                return obj;
            }

            if (Array.isArray(obj)) {
                return obj.filter(Boolean).map(this.filterObject);
            }

            return Object.entries(obj)
                .filter(([key, value]) => {
                    if (typeof value === 'object' && value !== null) {
                        const nonEmptyProps = Object.entries(value)
                            .filter(([nestedKey, nestedValue]) => nestedValue != null && nestedValue !== '');
                        if (nonEmptyProps.length === 0) {
                            return false;
                        }
                        const nonEmptyNestedObjs = nonEmptyProps.filter(([k, v]) => typeof v === 'object' && v !== null && Object.keys(v).length > 0);
                        if (nonEmptyProps.length === nonEmptyNestedObjs.length) {
                            return true;
                        }
                        return nonEmptyProps.length > 0;
                    }
                    return value != null && value !== '';
                })
                .filter(([key, value]) => value != null && value !== '')
                .reduce((acc, [key, value]) => {
                    if (typeof value === 'object' && value !== null) {
                        const filteredValue = this.filterObject(value);
                        if (Object.keys(filteredValue).length > 0) {
                            acc[key] = filteredValue;
                        }
                    } else {
                        acc[key] = value;
                    }
                    return acc;
                }, {});
        },
        copyCode(code) {
            this.$copyText(code).then((e) => {
                this.notify({ title: 'Success', text: `Code ${code} was successfully copied.`, icon: 'fas fa-clipboard', variant: 'success' });
            }, (e) => {
                console.error(e)
            })
        },
    },
}
