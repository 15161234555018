/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createApplication = /* GraphQL */ `
  mutation CreateApplication(
    $input: CreateApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    createApplication(input: $input, condition: $condition) {
      id
      festival {
        id
        slug
        name
        site
        zone {
          id
          name
          counties
          createdAt
          updatedAt
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        festivalZoneId
        __typename
      }
      form {
        id
        slug
        name
        type
        instruments {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      instrument {
        id
        slug
        name
        order
        form {
          id
          slug
          name
          type
          createdAt
          updatedAt
          __typename
        }
        ensembles {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        formInstrumentsId
        __typename
      }
      student {
        id
        name {
          first
          last
          preferred
          __typename
        }
        dob
        sex
        gender
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        grade
        school {
          id
          beds
          slug
          createdAt
          updatedAt
          districtSchoolsId
          schoolZoneId
          __typename
        }
        schoolID
        bus {
          id
          name
          capacity
          createdAt
          updatedAt
          __typename
        }
        applications {
          nextToken
          __typename
        }
        invoice {
          id
          number
          amount
          dueAt
          memo
          duplicates
          createdAt
          updatedAt
          invoiceStudentId
          __typename
        }
        eventId
        event {
          id
          studentId
          busId
          chaperoneId
          hotelId
          room
          signature
          medicalReceived
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        busStudentsId
        chaperoneStudentsId
        hotelStudentsId
        studentInvoiceId
        __typename
      }
      teacher {
        id
        username
        name {
          first
          last
          preferred
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        schools {
          nextToken
          __typename
        }
        ensembleIds
        instrumentIds
        zoneIds
        avatar {
          size
          name
          src
          __typename
        }
        createdAt
        updatedAt
        districtUsersId
        __typename
      }
      teacherID
      questions {
        lastYear {
          attended
          group
          part
          chair
          __typename
        }
        moreThanOne {
          response
          instruments
          __typename
        }
        organizations {
          school
          other
          __typename
        }
        experience {
          vocalJazzAccompaniment
          jazzEnsemble
          orchestral
          __typename
        }
        previousSoloRating {
          __typename
        }
        __typename
      }
      grading {
        tone
        rhythmicSense
        leadership
        maturity
        articulation
        intonation
        citizenship
        diction
        flexibility
        generalMusicianship
        attitude
        technique
        sightReading
        responsibility
        __typename
      }
      recommendation
      comments
      ranking {
        score
        local
        state
        __typename
      }
      selection {
        id
        part
        ranking {
          state
          __typename
        }
        ensemble {
          id
          slug
          name
          code
          parts
          chairId
          hasParts
          hasAlternates
          createdAt
          updatedAt
          __typename
        }
        accepted
        application {
          id
          teacherID
          recommendation
          comments
          verified
          createdAt
          updatedAt
          studentApplicationsId
          applicationFestivalId
          applicationFormId
          applicationInstrumentId
          applicationSelectionId
          __typename
        }
        state {
          order
          __typename
        }
        createdAt
        updatedAt
        selectionEnsembleId
        selectionApplicationId
        __typename
      }
      verified
      createdAt
      updatedAt
      studentApplicationsId
      applicationFestivalId
      applicationFormId
      applicationInstrumentId
      applicationSelectionId
      __typename
    }
  }
`;
export const updateApplication = /* GraphQL */ `
  mutation UpdateApplication(
    $input: UpdateApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    updateApplication(input: $input, condition: $condition) {
      id
      festival {
        id
        slug
        name
        site
        zone {
          id
          name
          counties
          createdAt
          updatedAt
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        festivalZoneId
        __typename
      }
      form {
        id
        slug
        name
        type
        instruments {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      instrument {
        id
        slug
        name
        order
        form {
          id
          slug
          name
          type
          createdAt
          updatedAt
          __typename
        }
        ensembles {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        formInstrumentsId
        __typename
      }
      student {
        id
        name {
          first
          last
          preferred
          __typename
        }
        dob
        sex
        gender
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        grade
        school {
          id
          beds
          slug
          createdAt
          updatedAt
          districtSchoolsId
          schoolZoneId
          __typename
        }
        schoolID
        bus {
          id
          name
          capacity
          createdAt
          updatedAt
          __typename
        }
        applications {
          nextToken
          __typename
        }
        invoice {
          id
          number
          amount
          dueAt
          memo
          duplicates
          createdAt
          updatedAt
          invoiceStudentId
          __typename
        }
        eventId
        event {
          id
          studentId
          busId
          chaperoneId
          hotelId
          room
          signature
          medicalReceived
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        busStudentsId
        chaperoneStudentsId
        hotelStudentsId
        studentInvoiceId
        __typename
      }
      teacher {
        id
        username
        name {
          first
          last
          preferred
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        schools {
          nextToken
          __typename
        }
        ensembleIds
        instrumentIds
        zoneIds
        avatar {
          size
          name
          src
          __typename
        }
        createdAt
        updatedAt
        districtUsersId
        __typename
      }
      teacherID
      questions {
        lastYear {
          attended
          group
          part
          chair
          __typename
        }
        moreThanOne {
          response
          instruments
          __typename
        }
        organizations {
          school
          other
          __typename
        }
        experience {
          vocalJazzAccompaniment
          jazzEnsemble
          orchestral
          __typename
        }
        previousSoloRating {
          __typename
        }
        __typename
      }
      grading {
        tone
        rhythmicSense
        leadership
        maturity
        articulation
        intonation
        citizenship
        diction
        flexibility
        generalMusicianship
        attitude
        technique
        sightReading
        responsibility
        __typename
      }
      recommendation
      comments
      ranking {
        score
        local
        state
        __typename
      }
      selection {
        id
        part
        ranking {
          state
          __typename
        }
        ensemble {
          id
          slug
          name
          code
          parts
          chairId
          hasParts
          hasAlternates
          createdAt
          updatedAt
          __typename
        }
        accepted
        application {
          id
          teacherID
          recommendation
          comments
          verified
          createdAt
          updatedAt
          studentApplicationsId
          applicationFestivalId
          applicationFormId
          applicationInstrumentId
          applicationSelectionId
          __typename
        }
        state {
          order
          __typename
        }
        createdAt
        updatedAt
        selectionEnsembleId
        selectionApplicationId
        __typename
      }
      verified
      createdAt
      updatedAt
      studentApplicationsId
      applicationFestivalId
      applicationFormId
      applicationInstrumentId
      applicationSelectionId
      __typename
    }
  }
`;
export const deleteApplication = /* GraphQL */ `
  mutation DeleteApplication(
    $input: DeleteApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    deleteApplication(input: $input, condition: $condition) {
      id
      festival {
        id
        slug
        name
        site
        zone {
          id
          name
          counties
          createdAt
          updatedAt
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        festivalZoneId
        __typename
      }
      form {
        id
        slug
        name
        type
        instruments {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      instrument {
        id
        slug
        name
        order
        form {
          id
          slug
          name
          type
          createdAt
          updatedAt
          __typename
        }
        ensembles {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        formInstrumentsId
        __typename
      }
      student {
        id
        name {
          first
          last
          preferred
          __typename
        }
        dob
        sex
        gender
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        grade
        school {
          id
          beds
          slug
          createdAt
          updatedAt
          districtSchoolsId
          schoolZoneId
          __typename
        }
        schoolID
        bus {
          id
          name
          capacity
          createdAt
          updatedAt
          __typename
        }
        applications {
          nextToken
          __typename
        }
        invoice {
          id
          number
          amount
          dueAt
          memo
          duplicates
          createdAt
          updatedAt
          invoiceStudentId
          __typename
        }
        eventId
        event {
          id
          studentId
          busId
          chaperoneId
          hotelId
          room
          signature
          medicalReceived
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        busStudentsId
        chaperoneStudentsId
        hotelStudentsId
        studentInvoiceId
        __typename
      }
      teacher {
        id
        username
        name {
          first
          last
          preferred
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        schools {
          nextToken
          __typename
        }
        ensembleIds
        instrumentIds
        zoneIds
        avatar {
          size
          name
          src
          __typename
        }
        createdAt
        updatedAt
        districtUsersId
        __typename
      }
      teacherID
      questions {
        lastYear {
          attended
          group
          part
          chair
          __typename
        }
        moreThanOne {
          response
          instruments
          __typename
        }
        organizations {
          school
          other
          __typename
        }
        experience {
          vocalJazzAccompaniment
          jazzEnsemble
          orchestral
          __typename
        }
        previousSoloRating {
          __typename
        }
        __typename
      }
      grading {
        tone
        rhythmicSense
        leadership
        maturity
        articulation
        intonation
        citizenship
        diction
        flexibility
        generalMusicianship
        attitude
        technique
        sightReading
        responsibility
        __typename
      }
      recommendation
      comments
      ranking {
        score
        local
        state
        __typename
      }
      selection {
        id
        part
        ranking {
          state
          __typename
        }
        ensemble {
          id
          slug
          name
          code
          parts
          chairId
          hasParts
          hasAlternates
          createdAt
          updatedAt
          __typename
        }
        accepted
        application {
          id
          teacherID
          recommendation
          comments
          verified
          createdAt
          updatedAt
          studentApplicationsId
          applicationFestivalId
          applicationFormId
          applicationInstrumentId
          applicationSelectionId
          __typename
        }
        state {
          order
          __typename
        }
        createdAt
        updatedAt
        selectionEnsembleId
        selectionApplicationId
        __typename
      }
      verified
      createdAt
      updatedAt
      studentApplicationsId
      applicationFestivalId
      applicationFormId
      applicationInstrumentId
      applicationSelectionId
      __typename
    }
  }
`;
export const createBill = /* GraphQL */ `
  mutation CreateBill(
    $input: CreateBillInput!
    $condition: ModelBillConditionInput
  ) {
    createBill(input: $input, condition: $condition) {
      id
      payments {
        type
        amount
        check
        received
        returned
        __typename
      }
      memo
      duplicates
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateBill = /* GraphQL */ `
  mutation UpdateBill(
    $input: UpdateBillInput!
    $condition: ModelBillConditionInput
  ) {
    updateBill(input: $input, condition: $condition) {
      id
      payments {
        type
        amount
        check
        received
        returned
        __typename
      }
      memo
      duplicates
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteBill = /* GraphQL */ `
  mutation DeleteBill(
    $input: DeleteBillInput!
    $condition: ModelBillConditionInput
  ) {
    deleteBill(input: $input, condition: $condition) {
      id
      payments {
        type
        amount
        check
        received
        returned
        __typename
      }
      memo
      duplicates
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDistrict = /* GraphQL */ `
  mutation CreateDistrict(
    $input: CreateDistrictInput!
    $condition: ModelDistrictConditionInput
  ) {
    createDistrict(input: $input, condition: $condition) {
      id
      beds
      slug
      name {
        legal
        popular
        __typename
      }
      avatar {
        size
        name
        src
        __typename
      }
      address {
        line1
        line2
        city
        county
        state
        zip
        __typename
      }
      zone {
        id
        name
        counties
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      schools {
        items {
          id
          beds
          slug
          createdAt
          updatedAt
          districtSchoolsId
          schoolZoneId
          __typename
        }
        nextToken
        __typename
      }
      staff {
        type
        name {
          first
          last
          preferred
          __typename
        }
        title
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        __typename
      }
      users {
        items {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        nextToken
        __typename
      }
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      districtZoneId
      __typename
    }
  }
`;
export const updateDistrict = /* GraphQL */ `
  mutation UpdateDistrict(
    $input: UpdateDistrictInput!
    $condition: ModelDistrictConditionInput
  ) {
    updateDistrict(input: $input, condition: $condition) {
      id
      beds
      slug
      name {
        legal
        popular
        __typename
      }
      avatar {
        size
        name
        src
        __typename
      }
      address {
        line1
        line2
        city
        county
        state
        zip
        __typename
      }
      zone {
        id
        name
        counties
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      schools {
        items {
          id
          beds
          slug
          createdAt
          updatedAt
          districtSchoolsId
          schoolZoneId
          __typename
        }
        nextToken
        __typename
      }
      staff {
        type
        name {
          first
          last
          preferred
          __typename
        }
        title
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        __typename
      }
      users {
        items {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        nextToken
        __typename
      }
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      districtZoneId
      __typename
    }
  }
`;
export const deleteDistrict = /* GraphQL */ `
  mutation DeleteDistrict(
    $input: DeleteDistrictInput!
    $condition: ModelDistrictConditionInput
  ) {
    deleteDistrict(input: $input, condition: $condition) {
      id
      beds
      slug
      name {
        legal
        popular
        __typename
      }
      avatar {
        size
        name
        src
        __typename
      }
      address {
        line1
        line2
        city
        county
        state
        zip
        __typename
      }
      zone {
        id
        name
        counties
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      schools {
        items {
          id
          beds
          slug
          createdAt
          updatedAt
          districtSchoolsId
          schoolZoneId
          __typename
        }
        nextToken
        __typename
      }
      staff {
        type
        name {
          first
          last
          preferred
          __typename
        }
        title
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        __typename
      }
      users {
        items {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        nextToken
        __typename
      }
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      districtZoneId
      __typename
    }
  }
`;
export const createDocumentCategory = /* GraphQL */ `
  mutation CreateDocumentCategory(
    $input: CreateDocumentCategoryInput!
    $condition: ModelDocumentCategoryConditionInput
  ) {
    createDocumentCategory(input: $input, condition: $condition) {
      id
      slug
      title
      description
      icon
      documents {
        items {
          id
          slug
          categoryID
          title
          description
          content
          isPublished
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDocumentCategory = /* GraphQL */ `
  mutation UpdateDocumentCategory(
    $input: UpdateDocumentCategoryInput!
    $condition: ModelDocumentCategoryConditionInput
  ) {
    updateDocumentCategory(input: $input, condition: $condition) {
      id
      slug
      title
      description
      icon
      documents {
        items {
          id
          slug
          categoryID
          title
          description
          content
          isPublished
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDocumentCategory = /* GraphQL */ `
  mutation DeleteDocumentCategory(
    $input: DeleteDocumentCategoryInput!
    $condition: ModelDocumentCategoryConditionInput
  ) {
    deleteDocumentCategory(input: $input, condition: $condition) {
      id
      slug
      title
      description
      icon
      documents {
        items {
          id
          slug
          categoryID
          title
          description
          content
          isPublished
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDocument = /* GraphQL */ `
  mutation CreateDocument(
    $input: CreateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    createDocument(input: $input, condition: $condition) {
      id
      slug
      category {
        id
        slug
        title
        description
        icon
        documents {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      categoryID
      title
      description
      content
      isPublished
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDocument = /* GraphQL */ `
  mutation UpdateDocument(
    $input: UpdateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    updateDocument(input: $input, condition: $condition) {
      id
      slug
      category {
        id
        slug
        title
        description
        icon
        documents {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      categoryID
      title
      description
      content
      isPublished
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDocument = /* GraphQL */ `
  mutation DeleteDocument(
    $input: DeleteDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    deleteDocument(input: $input, condition: $condition) {
      id
      slug
      category {
        id
        slug
        title
        description
        icon
        documents {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      categoryID
      title
      description
      content
      isPublished
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEnsemble = /* GraphQL */ `
  mutation CreateEnsemble(
    $input: CreateEnsembleInput!
    $condition: ModelEnsembleConditionInput
  ) {
    createEnsemble(input: $input, condition: $condition) {
      id
      slug
      name
      code
      parts
      instruments {
        items {
          id
          ensembleID
          instrumentID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      chair {
        id
        username
        name {
          first
          last
          preferred
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        schools {
          nextToken
          __typename
        }
        ensembleIds
        instrumentIds
        zoneIds
        avatar {
          size
          name
          src
          __typename
        }
        createdAt
        updatedAt
        districtUsersId
        __typename
      }
      chairId
      hasParts
      hasAlternates
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEnsemble = /* GraphQL */ `
  mutation UpdateEnsemble(
    $input: UpdateEnsembleInput!
    $condition: ModelEnsembleConditionInput
  ) {
    updateEnsemble(input: $input, condition: $condition) {
      id
      slug
      name
      code
      parts
      instruments {
        items {
          id
          ensembleID
          instrumentID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      chair {
        id
        username
        name {
          first
          last
          preferred
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        schools {
          nextToken
          __typename
        }
        ensembleIds
        instrumentIds
        zoneIds
        avatar {
          size
          name
          src
          __typename
        }
        createdAt
        updatedAt
        districtUsersId
        __typename
      }
      chairId
      hasParts
      hasAlternates
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEnsemble = /* GraphQL */ `
  mutation DeleteEnsemble(
    $input: DeleteEnsembleInput!
    $condition: ModelEnsembleConditionInput
  ) {
    deleteEnsemble(input: $input, condition: $condition) {
      id
      slug
      name
      code
      parts
      instruments {
        items {
          id
          ensembleID
          instrumentID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      chair {
        id
        username
        name {
          first
          last
          preferred
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        schools {
          nextToken
          __typename
        }
        ensembleIds
        instrumentIds
        zoneIds
        avatar {
          size
          name
          src
          __typename
        }
        createdAt
        updatedAt
        districtUsersId
        __typename
      }
      chairId
      hasParts
      hasAlternates
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFestival = /* GraphQL */ `
  mutation CreateFestival(
    $input: CreateFestivalInput!
    $condition: ModelFestivalConditionInput
  ) {
    createFestival(input: $input, condition: $condition) {
      id
      slug
      name
      site
      zone {
        id
        name
        counties
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      address {
        line1
        line2
        city
        county
        state
        zip
        __typename
      }
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      festivalZoneId
      __typename
    }
  }
`;
export const updateFestival = /* GraphQL */ `
  mutation UpdateFestival(
    $input: UpdateFestivalInput!
    $condition: ModelFestivalConditionInput
  ) {
    updateFestival(input: $input, condition: $condition) {
      id
      slug
      name
      site
      zone {
        id
        name
        counties
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      address {
        line1
        line2
        city
        county
        state
        zip
        __typename
      }
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      festivalZoneId
      __typename
    }
  }
`;
export const deleteFestival = /* GraphQL */ `
  mutation DeleteFestival(
    $input: DeleteFestivalInput!
    $condition: ModelFestivalConditionInput
  ) {
    deleteFestival(input: $input, condition: $condition) {
      id
      slug
      name
      site
      zone {
        id
        name
        counties
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      address {
        line1
        line2
        city
        county
        state
        zip
        __typename
      }
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      festivalZoneId
      __typename
    }
  }
`;
export const createForm = /* GraphQL */ `
  mutation CreateForm(
    $input: CreateFormInput!
    $condition: ModelFormConditionInput
  ) {
    createForm(input: $input, condition: $condition) {
      id
      slug
      name
      type
      instruments {
        items {
          id
          slug
          name
          order
          createdAt
          updatedAt
          formInstrumentsId
          __typename
        }
        nextToken
        __typename
      }
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateForm = /* GraphQL */ `
  mutation UpdateForm(
    $input: UpdateFormInput!
    $condition: ModelFormConditionInput
  ) {
    updateForm(input: $input, condition: $condition) {
      id
      slug
      name
      type
      instruments {
        items {
          id
          slug
          name
          order
          createdAt
          updatedAt
          formInstrumentsId
          __typename
        }
        nextToken
        __typename
      }
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteForm = /* GraphQL */ `
  mutation DeleteForm(
    $input: DeleteFormInput!
    $condition: ModelFormConditionInput
  ) {
    deleteForm(input: $input, condition: $condition) {
      id
      slug
      name
      type
      instruments {
        items {
          id
          slug
          name
          order
          createdAt
          updatedAt
          formInstrumentsId
          __typename
        }
        nextToken
        __typename
      }
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createInstrument = /* GraphQL */ `
  mutation CreateInstrument(
    $input: CreateInstrumentInput!
    $condition: ModelInstrumentConditionInput
  ) {
    createInstrument(input: $input, condition: $condition) {
      id
      slug
      name
      order
      form {
        id
        slug
        name
        type
        instruments {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ensembles {
        items {
          id
          ensembleID
          instrumentID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      formInstrumentsId
      __typename
    }
  }
`;
export const updateInstrument = /* GraphQL */ `
  mutation UpdateInstrument(
    $input: UpdateInstrumentInput!
    $condition: ModelInstrumentConditionInput
  ) {
    updateInstrument(input: $input, condition: $condition) {
      id
      slug
      name
      order
      form {
        id
        slug
        name
        type
        instruments {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ensembles {
        items {
          id
          ensembleID
          instrumentID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      formInstrumentsId
      __typename
    }
  }
`;
export const deleteInstrument = /* GraphQL */ `
  mutation DeleteInstrument(
    $input: DeleteInstrumentInput!
    $condition: ModelInstrumentConditionInput
  ) {
    deleteInstrument(input: $input, condition: $condition) {
      id
      slug
      name
      order
      form {
        id
        slug
        name
        type
        instruments {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ensembles {
        items {
          id
          ensembleID
          instrumentID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      formInstrumentsId
      __typename
    }
  }
`;
export const createInvoice = /* GraphQL */ `
  mutation CreateInvoice(
    $input: CreateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    createInvoice(input: $input, condition: $condition) {
      id
      number
      amount
      dueAt
      memo
      duplicates
      payments {
        items {
          type
          amount
          check
          received
          returned
          createdAt
          updatedAt
          id
          invoicePaymentsId
          __typename
        }
        nextToken
        __typename
      }
      student {
        id
        name {
          first
          last
          preferred
          __typename
        }
        dob
        sex
        gender
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        grade
        school {
          id
          beds
          slug
          createdAt
          updatedAt
          districtSchoolsId
          schoolZoneId
          __typename
        }
        schoolID
        bus {
          id
          name
          capacity
          createdAt
          updatedAt
          __typename
        }
        applications {
          nextToken
          __typename
        }
        invoice {
          id
          number
          amount
          dueAt
          memo
          duplicates
          createdAt
          updatedAt
          invoiceStudentId
          __typename
        }
        eventId
        event {
          id
          studentId
          busId
          chaperoneId
          hotelId
          room
          signature
          medicalReceived
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        busStudentsId
        chaperoneStudentsId
        hotelStudentsId
        studentInvoiceId
        __typename
      }
      createdAt
      updatedAt
      invoiceStudentId
      __typename
    }
  }
`;
export const updateInvoice = /* GraphQL */ `
  mutation UpdateInvoice(
    $input: UpdateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    updateInvoice(input: $input, condition: $condition) {
      id
      number
      amount
      dueAt
      memo
      duplicates
      payments {
        items {
          type
          amount
          check
          received
          returned
          createdAt
          updatedAt
          id
          invoicePaymentsId
          __typename
        }
        nextToken
        __typename
      }
      student {
        id
        name {
          first
          last
          preferred
          __typename
        }
        dob
        sex
        gender
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        grade
        school {
          id
          beds
          slug
          createdAt
          updatedAt
          districtSchoolsId
          schoolZoneId
          __typename
        }
        schoolID
        bus {
          id
          name
          capacity
          createdAt
          updatedAt
          __typename
        }
        applications {
          nextToken
          __typename
        }
        invoice {
          id
          number
          amount
          dueAt
          memo
          duplicates
          createdAt
          updatedAt
          invoiceStudentId
          __typename
        }
        eventId
        event {
          id
          studentId
          busId
          chaperoneId
          hotelId
          room
          signature
          medicalReceived
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        busStudentsId
        chaperoneStudentsId
        hotelStudentsId
        studentInvoiceId
        __typename
      }
      createdAt
      updatedAt
      invoiceStudentId
      __typename
    }
  }
`;
export const deleteInvoice = /* GraphQL */ `
  mutation DeleteInvoice(
    $input: DeleteInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    deleteInvoice(input: $input, condition: $condition) {
      id
      number
      amount
      dueAt
      memo
      duplicates
      payments {
        items {
          type
          amount
          check
          received
          returned
          createdAt
          updatedAt
          id
          invoicePaymentsId
          __typename
        }
        nextToken
        __typename
      }
      student {
        id
        name {
          first
          last
          preferred
          __typename
        }
        dob
        sex
        gender
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        grade
        school {
          id
          beds
          slug
          createdAt
          updatedAt
          districtSchoolsId
          schoolZoneId
          __typename
        }
        schoolID
        bus {
          id
          name
          capacity
          createdAt
          updatedAt
          __typename
        }
        applications {
          nextToken
          __typename
        }
        invoice {
          id
          number
          amount
          dueAt
          memo
          duplicates
          createdAt
          updatedAt
          invoiceStudentId
          __typename
        }
        eventId
        event {
          id
          studentId
          busId
          chaperoneId
          hotelId
          room
          signature
          medicalReceived
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        busStudentsId
        chaperoneStudentsId
        hotelStudentsId
        studentInvoiceId
        __typename
      }
      createdAt
      updatedAt
      invoiceStudentId
      __typename
    }
  }
`;
export const createPurchaseOrder = /* GraphQL */ `
  mutation CreatePurchaseOrder(
    $input: CreatePurchaseOrderInput!
    $condition: ModelPurchaseOrderConditionInput
  ) {
    createPurchaseOrder(input: $input, condition: $condition) {
      type
      amount
      check
      received
      returned
      createdAt
      updatedAt
      id
      __typename
    }
  }
`;
export const updatePurchaseOrder = /* GraphQL */ `
  mutation UpdatePurchaseOrder(
    $input: UpdatePurchaseOrderInput!
    $condition: ModelPurchaseOrderConditionInput
  ) {
    updatePurchaseOrder(input: $input, condition: $condition) {
      type
      amount
      check
      received
      returned
      createdAt
      updatedAt
      id
      __typename
    }
  }
`;
export const deletePurchaseOrder = /* GraphQL */ `
  mutation DeletePurchaseOrder(
    $input: DeletePurchaseOrderInput!
    $condition: ModelPurchaseOrderConditionInput
  ) {
    deletePurchaseOrder(input: $input, condition: $condition) {
      type
      amount
      check
      received
      returned
      createdAt
      updatedAt
      id
      __typename
    }
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment(
    $input: CreatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    createPayment(input: $input, condition: $condition) {
      type
      amount
      check
      received
      returned
      invoice {
        id
        number
        amount
        dueAt
        memo
        duplicates
        payments {
          nextToken
          __typename
        }
        student {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        createdAt
        updatedAt
        invoiceStudentId
        __typename
      }
      createdAt
      updatedAt
      id
      invoicePaymentsId
      __typename
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment(
    $input: UpdatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    updatePayment(input: $input, condition: $condition) {
      type
      amount
      check
      received
      returned
      invoice {
        id
        number
        amount
        dueAt
        memo
        duplicates
        payments {
          nextToken
          __typename
        }
        student {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        createdAt
        updatedAt
        invoiceStudentId
        __typename
      }
      createdAt
      updatedAt
      id
      invoicePaymentsId
      __typename
    }
  }
`;
export const deletePayment = /* GraphQL */ `
  mutation DeletePayment(
    $input: DeletePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    deletePayment(input: $input, condition: $condition) {
      type
      amount
      check
      received
      returned
      invoice {
        id
        number
        amount
        dueAt
        memo
        duplicates
        payments {
          nextToken
          __typename
        }
        student {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        createdAt
        updatedAt
        invoiceStudentId
        __typename
      }
      createdAt
      updatedAt
      id
      invoicePaymentsId
      __typename
    }
  }
`;
export const createSchool = /* GraphQL */ `
  mutation CreateSchool(
    $input: CreateSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    createSchool(input: $input, condition: $condition) {
      id
      beds
      slug
      name {
        legal
        popular
        __typename
      }
      avatar {
        size
        name
        src
        __typename
      }
      address {
        line1
        line2
        city
        county
        state
        zip
        __typename
      }
      zone {
        id
        name
        counties
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      district {
        id
        beds
        slug
        name {
          legal
          popular
          __typename
        }
        avatar {
          size
          name
          src
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        zone {
          id
          name
          counties
          createdAt
          updatedAt
          __typename
        }
        schools {
          nextToken
          __typename
        }
        staff {
          type
          title
          __typename
        }
        users {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        districtZoneId
        __typename
      }
      staff {
        type
        name {
          first
          last
          preferred
          __typename
        }
        title
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        __typename
      }
      users {
        items {
          id
          schoolID
          userID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      districtSchoolsId
      schoolZoneId
      __typename
    }
  }
`;
export const updateSchool = /* GraphQL */ `
  mutation UpdateSchool(
    $input: UpdateSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    updateSchool(input: $input, condition: $condition) {
      id
      beds
      slug
      name {
        legal
        popular
        __typename
      }
      avatar {
        size
        name
        src
        __typename
      }
      address {
        line1
        line2
        city
        county
        state
        zip
        __typename
      }
      zone {
        id
        name
        counties
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      district {
        id
        beds
        slug
        name {
          legal
          popular
          __typename
        }
        avatar {
          size
          name
          src
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        zone {
          id
          name
          counties
          createdAt
          updatedAt
          __typename
        }
        schools {
          nextToken
          __typename
        }
        staff {
          type
          title
          __typename
        }
        users {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        districtZoneId
        __typename
      }
      staff {
        type
        name {
          first
          last
          preferred
          __typename
        }
        title
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        __typename
      }
      users {
        items {
          id
          schoolID
          userID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      districtSchoolsId
      schoolZoneId
      __typename
    }
  }
`;
export const deleteSchool = /* GraphQL */ `
  mutation DeleteSchool(
    $input: DeleteSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    deleteSchool(input: $input, condition: $condition) {
      id
      beds
      slug
      name {
        legal
        popular
        __typename
      }
      avatar {
        size
        name
        src
        __typename
      }
      address {
        line1
        line2
        city
        county
        state
        zip
        __typename
      }
      zone {
        id
        name
        counties
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      district {
        id
        beds
        slug
        name {
          legal
          popular
          __typename
        }
        avatar {
          size
          name
          src
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        zone {
          id
          name
          counties
          createdAt
          updatedAt
          __typename
        }
        schools {
          nextToken
          __typename
        }
        staff {
          type
          title
          __typename
        }
        users {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        districtZoneId
        __typename
      }
      staff {
        type
        name {
          first
          last
          preferred
          __typename
        }
        title
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        __typename
      }
      users {
        items {
          id
          schoolID
          userID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      districtSchoolsId
      schoolZoneId
      __typename
    }
  }
`;
export const createSelection = /* GraphQL */ `
  mutation CreateSelection(
    $input: CreateSelectionInput!
    $condition: ModelSelectionConditionInput
  ) {
    createSelection(input: $input, condition: $condition) {
      id
      part
      ranking {
        state
        __typename
      }
      ensemble {
        id
        slug
        name
        code
        parts
        instruments {
          nextToken
          __typename
        }
        chair {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        chairId
        hasParts
        hasAlternates
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      accepted
      application {
        id
        festival {
          id
          slug
          name
          site
          createdAt
          updatedAt
          festivalZoneId
          __typename
        }
        form {
          id
          slug
          name
          type
          createdAt
          updatedAt
          __typename
        }
        instrument {
          id
          slug
          name
          order
          createdAt
          updatedAt
          formInstrumentsId
          __typename
        }
        student {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        teacher {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        teacherID
        questions {
          __typename
        }
        grading {
          tone
          rhythmicSense
          leadership
          maturity
          articulation
          intonation
          citizenship
          diction
          flexibility
          generalMusicianship
          attitude
          technique
          sightReading
          responsibility
          __typename
        }
        recommendation
        comments
        ranking {
          score
          local
          state
          __typename
        }
        selection {
          id
          part
          accepted
          createdAt
          updatedAt
          selectionEnsembleId
          selectionApplicationId
          __typename
        }
        verified
        createdAt
        updatedAt
        studentApplicationsId
        applicationFestivalId
        applicationFormId
        applicationInstrumentId
        applicationSelectionId
        __typename
      }
      state {
        order
        __typename
      }
      createdAt
      updatedAt
      selectionEnsembleId
      selectionApplicationId
      __typename
    }
  }
`;
export const updateSelection = /* GraphQL */ `
  mutation UpdateSelection(
    $input: UpdateSelectionInput!
    $condition: ModelSelectionConditionInput
  ) {
    updateSelection(input: $input, condition: $condition) {
      id
      part
      ranking {
        state
        __typename
      }
      ensemble {
        id
        slug
        name
        code
        parts
        instruments {
          nextToken
          __typename
        }
        chair {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        chairId
        hasParts
        hasAlternates
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      accepted
      application {
        id
        festival {
          id
          slug
          name
          site
          createdAt
          updatedAt
          festivalZoneId
          __typename
        }
        form {
          id
          slug
          name
          type
          createdAt
          updatedAt
          __typename
        }
        instrument {
          id
          slug
          name
          order
          createdAt
          updatedAt
          formInstrumentsId
          __typename
        }
        student {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        teacher {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        teacherID
        questions {
          __typename
        }
        grading {
          tone
          rhythmicSense
          leadership
          maturity
          articulation
          intonation
          citizenship
          diction
          flexibility
          generalMusicianship
          attitude
          technique
          sightReading
          responsibility
          __typename
        }
        recommendation
        comments
        ranking {
          score
          local
          state
          __typename
        }
        selection {
          id
          part
          accepted
          createdAt
          updatedAt
          selectionEnsembleId
          selectionApplicationId
          __typename
        }
        verified
        createdAt
        updatedAt
        studentApplicationsId
        applicationFestivalId
        applicationFormId
        applicationInstrumentId
        applicationSelectionId
        __typename
      }
      state {
        order
        __typename
      }
      createdAt
      updatedAt
      selectionEnsembleId
      selectionApplicationId
      __typename
    }
  }
`;
export const deleteSelection = /* GraphQL */ `
  mutation DeleteSelection(
    $input: DeleteSelectionInput!
    $condition: ModelSelectionConditionInput
  ) {
    deleteSelection(input: $input, condition: $condition) {
      id
      part
      ranking {
        state
        __typename
      }
      ensemble {
        id
        slug
        name
        code
        parts
        instruments {
          nextToken
          __typename
        }
        chair {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        chairId
        hasParts
        hasAlternates
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      accepted
      application {
        id
        festival {
          id
          slug
          name
          site
          createdAt
          updatedAt
          festivalZoneId
          __typename
        }
        form {
          id
          slug
          name
          type
          createdAt
          updatedAt
          __typename
        }
        instrument {
          id
          slug
          name
          order
          createdAt
          updatedAt
          formInstrumentsId
          __typename
        }
        student {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        teacher {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        teacherID
        questions {
          __typename
        }
        grading {
          tone
          rhythmicSense
          leadership
          maturity
          articulation
          intonation
          citizenship
          diction
          flexibility
          generalMusicianship
          attitude
          technique
          sightReading
          responsibility
          __typename
        }
        recommendation
        comments
        ranking {
          score
          local
          state
          __typename
        }
        selection {
          id
          part
          accepted
          createdAt
          updatedAt
          selectionEnsembleId
          selectionApplicationId
          __typename
        }
        verified
        createdAt
        updatedAt
        studentApplicationsId
        applicationFestivalId
        applicationFormId
        applicationInstrumentId
        applicationSelectionId
        __typename
      }
      state {
        order
        __typename
      }
      createdAt
      updatedAt
      selectionEnsembleId
      selectionApplicationId
      __typename
    }
  }
`;
export const createSetting = /* GraphQL */ `
  mutation CreateSetting(
    $input: CreateSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    createSetting(input: $input, condition: $condition) {
      key
      value
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSetting = /* GraphQL */ `
  mutation UpdateSetting(
    $input: UpdateSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    updateSetting(input: $input, condition: $condition) {
      key
      value
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSetting = /* GraphQL */ `
  mutation DeleteSetting(
    $input: DeleteSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    deleteSetting(input: $input, condition: $condition) {
      key
      value
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createStudent = /* GraphQL */ `
  mutation CreateStudent(
    $input: CreateStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    createStudent(input: $input, condition: $condition) {
      id
      name {
        first
        last
        preferred
        __typename
      }
      dob
      sex
      gender
      address {
        line1
        line2
        city
        county
        state
        zip
        __typename
      }
      phone {
        type
        number
        ext
        __typename
      }
      email {
        type
        address
        __typename
      }
      grade
      school {
        id
        beds
        slug
        name {
          legal
          popular
          __typename
        }
        avatar {
          size
          name
          src
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        zone {
          id
          name
          counties
          createdAt
          updatedAt
          __typename
        }
        district {
          id
          beds
          slug
          createdAt
          updatedAt
          districtZoneId
          __typename
        }
        staff {
          type
          title
          __typename
        }
        users {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        districtSchoolsId
        schoolZoneId
        __typename
      }
      schoolID
      bus {
        id
        name
        capacity
        students {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      applications {
        items {
          id
          teacherID
          recommendation
          comments
          verified
          createdAt
          updatedAt
          studentApplicationsId
          applicationFestivalId
          applicationFormId
          applicationInstrumentId
          applicationSelectionId
          __typename
        }
        nextToken
        __typename
      }
      invoice {
        id
        number
        amount
        dueAt
        memo
        duplicates
        payments {
          nextToken
          __typename
        }
        student {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        createdAt
        updatedAt
        invoiceStudentId
        __typename
      }
      eventId
      event {
        id
        studentId
        busId
        chaperoneId
        hotelId
        room
        checkin {
          thursday
          friday
          saturday
          __typename
        }
        signature
        medicalReceived
        notes {
          id
          type
          text
          __typename
        }
        student {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        bus {
          id
          name
          capacity
          createdAt
          updatedAt
          __typename
        }
        chaperone {
          id
          role
          room
          hotelId
          createdAt
          updatedAt
          __typename
        }
        hotel {
          id
          name
          medicalSuite
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      busStudentsId
      chaperoneStudentsId
      hotelStudentsId
      studentInvoiceId
      __typename
    }
  }
`;
export const updateStudent = /* GraphQL */ `
  mutation UpdateStudent(
    $input: UpdateStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    updateStudent(input: $input, condition: $condition) {
      id
      name {
        first
        last
        preferred
        __typename
      }
      dob
      sex
      gender
      address {
        line1
        line2
        city
        county
        state
        zip
        __typename
      }
      phone {
        type
        number
        ext
        __typename
      }
      email {
        type
        address
        __typename
      }
      grade
      school {
        id
        beds
        slug
        name {
          legal
          popular
          __typename
        }
        avatar {
          size
          name
          src
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        zone {
          id
          name
          counties
          createdAt
          updatedAt
          __typename
        }
        district {
          id
          beds
          slug
          createdAt
          updatedAt
          districtZoneId
          __typename
        }
        staff {
          type
          title
          __typename
        }
        users {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        districtSchoolsId
        schoolZoneId
        __typename
      }
      schoolID
      bus {
        id
        name
        capacity
        students {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      applications {
        items {
          id
          teacherID
          recommendation
          comments
          verified
          createdAt
          updatedAt
          studentApplicationsId
          applicationFestivalId
          applicationFormId
          applicationInstrumentId
          applicationSelectionId
          __typename
        }
        nextToken
        __typename
      }
      invoice {
        id
        number
        amount
        dueAt
        memo
        duplicates
        payments {
          nextToken
          __typename
        }
        student {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        createdAt
        updatedAt
        invoiceStudentId
        __typename
      }
      eventId
      event {
        id
        studentId
        busId
        chaperoneId
        hotelId
        room
        checkin {
          thursday
          friday
          saturday
          __typename
        }
        signature
        medicalReceived
        notes {
          id
          type
          text
          __typename
        }
        student {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        bus {
          id
          name
          capacity
          createdAt
          updatedAt
          __typename
        }
        chaperone {
          id
          role
          room
          hotelId
          createdAt
          updatedAt
          __typename
        }
        hotel {
          id
          name
          medicalSuite
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      busStudentsId
      chaperoneStudentsId
      hotelStudentsId
      studentInvoiceId
      __typename
    }
  }
`;
export const deleteStudent = /* GraphQL */ `
  mutation DeleteStudent(
    $input: DeleteStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    deleteStudent(input: $input, condition: $condition) {
      id
      name {
        first
        last
        preferred
        __typename
      }
      dob
      sex
      gender
      address {
        line1
        line2
        city
        county
        state
        zip
        __typename
      }
      phone {
        type
        number
        ext
        __typename
      }
      email {
        type
        address
        __typename
      }
      grade
      school {
        id
        beds
        slug
        name {
          legal
          popular
          __typename
        }
        avatar {
          size
          name
          src
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        zone {
          id
          name
          counties
          createdAt
          updatedAt
          __typename
        }
        district {
          id
          beds
          slug
          createdAt
          updatedAt
          districtZoneId
          __typename
        }
        staff {
          type
          title
          __typename
        }
        users {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        districtSchoolsId
        schoolZoneId
        __typename
      }
      schoolID
      bus {
        id
        name
        capacity
        students {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      applications {
        items {
          id
          teacherID
          recommendation
          comments
          verified
          createdAt
          updatedAt
          studentApplicationsId
          applicationFestivalId
          applicationFormId
          applicationInstrumentId
          applicationSelectionId
          __typename
        }
        nextToken
        __typename
      }
      invoice {
        id
        number
        amount
        dueAt
        memo
        duplicates
        payments {
          nextToken
          __typename
        }
        student {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        createdAt
        updatedAt
        invoiceStudentId
        __typename
      }
      eventId
      event {
        id
        studentId
        busId
        chaperoneId
        hotelId
        room
        checkin {
          thursday
          friday
          saturday
          __typename
        }
        signature
        medicalReceived
        notes {
          id
          type
          text
          __typename
        }
        student {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        bus {
          id
          name
          capacity
          createdAt
          updatedAt
          __typename
        }
        chaperone {
          id
          role
          room
          hotelId
          createdAt
          updatedAt
          __typename
        }
        hotel {
          id
          name
          medicalSuite
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      busStudentsId
      chaperoneStudentsId
      hotelStudentsId
      studentInvoiceId
      __typename
    }
  }
`;
export const createTicket = /* GraphQL */ `
  mutation CreateTicket(
    $input: CreateTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    createTicket(input: $input, condition: $condition) {
      id
      title
      text
      status
      labels
      user {
        id
        username
        name {
          first
          last
          preferred
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        schools {
          nextToken
          __typename
        }
        ensembleIds
        instrumentIds
        zoneIds
        avatar {
          size
          name
          src
          __typename
        }
        createdAt
        updatedAt
        districtUsersId
        __typename
      }
      userID
      replies {
        items {
          id
          text
          isAutomatedReply
          createdAt
          updatedAt
          ticketRepliesId
          ticketReplyUserId
          __typename
        }
        nextToken
        __typename
      }
      state {
        locked
        hidden
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTicket = /* GraphQL */ `
  mutation UpdateTicket(
    $input: UpdateTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    updateTicket(input: $input, condition: $condition) {
      id
      title
      text
      status
      labels
      user {
        id
        username
        name {
          first
          last
          preferred
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        schools {
          nextToken
          __typename
        }
        ensembleIds
        instrumentIds
        zoneIds
        avatar {
          size
          name
          src
          __typename
        }
        createdAt
        updatedAt
        districtUsersId
        __typename
      }
      userID
      replies {
        items {
          id
          text
          isAutomatedReply
          createdAt
          updatedAt
          ticketRepliesId
          ticketReplyUserId
          __typename
        }
        nextToken
        __typename
      }
      state {
        locked
        hidden
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTicket = /* GraphQL */ `
  mutation DeleteTicket(
    $input: DeleteTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    deleteTicket(input: $input, condition: $condition) {
      id
      title
      text
      status
      labels
      user {
        id
        username
        name {
          first
          last
          preferred
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        schools {
          nextToken
          __typename
        }
        ensembleIds
        instrumentIds
        zoneIds
        avatar {
          size
          name
          src
          __typename
        }
        createdAt
        updatedAt
        districtUsersId
        __typename
      }
      userID
      replies {
        items {
          id
          text
          isAutomatedReply
          createdAt
          updatedAt
          ticketRepliesId
          ticketReplyUserId
          __typename
        }
        nextToken
        __typename
      }
      state {
        locked
        hidden
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTicketReply = /* GraphQL */ `
  mutation CreateTicketReply(
    $input: CreateTicketReplyInput!
    $condition: ModelTicketReplyConditionInput
  ) {
    createTicketReply(input: $input, condition: $condition) {
      id
      text
      user {
        id
        username
        name {
          first
          last
          preferred
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        schools {
          nextToken
          __typename
        }
        ensembleIds
        instrumentIds
        zoneIds
        avatar {
          size
          name
          src
          __typename
        }
        createdAt
        updatedAt
        districtUsersId
        __typename
      }
      ticket {
        id
        title
        text
        status
        labels
        user {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        userID
        replies {
          nextToken
          __typename
        }
        state {
          locked
          hidden
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      state {
        locked
        hidden
        __typename
      }
      isAutomatedReply
      createdAt
      updatedAt
      ticketRepliesId
      ticketReplyUserId
      __typename
    }
  }
`;
export const updateTicketReply = /* GraphQL */ `
  mutation UpdateTicketReply(
    $input: UpdateTicketReplyInput!
    $condition: ModelTicketReplyConditionInput
  ) {
    updateTicketReply(input: $input, condition: $condition) {
      id
      text
      user {
        id
        username
        name {
          first
          last
          preferred
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        schools {
          nextToken
          __typename
        }
        ensembleIds
        instrumentIds
        zoneIds
        avatar {
          size
          name
          src
          __typename
        }
        createdAt
        updatedAt
        districtUsersId
        __typename
      }
      ticket {
        id
        title
        text
        status
        labels
        user {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        userID
        replies {
          nextToken
          __typename
        }
        state {
          locked
          hidden
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      state {
        locked
        hidden
        __typename
      }
      isAutomatedReply
      createdAt
      updatedAt
      ticketRepliesId
      ticketReplyUserId
      __typename
    }
  }
`;
export const deleteTicketReply = /* GraphQL */ `
  mutation DeleteTicketReply(
    $input: DeleteTicketReplyInput!
    $condition: ModelTicketReplyConditionInput
  ) {
    deleteTicketReply(input: $input, condition: $condition) {
      id
      text
      user {
        id
        username
        name {
          first
          last
          preferred
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        schools {
          nextToken
          __typename
        }
        ensembleIds
        instrumentIds
        zoneIds
        avatar {
          size
          name
          src
          __typename
        }
        createdAt
        updatedAt
        districtUsersId
        __typename
      }
      ticket {
        id
        title
        text
        status
        labels
        user {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        userID
        replies {
          nextToken
          __typename
        }
        state {
          locked
          hidden
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      state {
        locked
        hidden
        __typename
      }
      isAutomatedReply
      createdAt
      updatedAt
      ticketRepliesId
      ticketReplyUserId
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      name {
        first
        last
        preferred
        __typename
      }
      address {
        line1
        line2
        city
        county
        state
        zip
        __typename
      }
      schools {
        items {
          id
          schoolID
          userID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      ensembleIds
      instrumentIds
      zoneIds
      avatar {
        size
        name
        src
        __typename
      }
      createdAt
      updatedAt
      districtUsersId
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      name {
        first
        last
        preferred
        __typename
      }
      address {
        line1
        line2
        city
        county
        state
        zip
        __typename
      }
      schools {
        items {
          id
          schoolID
          userID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      ensembleIds
      instrumentIds
      zoneIds
      avatar {
        size
        name
        src
        __typename
      }
      createdAt
      updatedAt
      districtUsersId
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      name {
        first
        last
        preferred
        __typename
      }
      address {
        line1
        line2
        city
        county
        state
        zip
        __typename
      }
      schools {
        items {
          id
          schoolID
          userID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      ensembleIds
      instrumentIds
      zoneIds
      avatar {
        size
        name
        src
        __typename
      }
      createdAt
      updatedAt
      districtUsersId
      __typename
    }
  }
`;
export const createZone = /* GraphQL */ `
  mutation CreateZone(
    $input: CreateZoneInput!
    $condition: ModelZoneConditionInput
  ) {
    createZone(input: $input, condition: $condition) {
      id
      name
      counties
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateZone = /* GraphQL */ `
  mutation UpdateZone(
    $input: UpdateZoneInput!
    $condition: ModelZoneConditionInput
  ) {
    updateZone(input: $input, condition: $condition) {
      id
      name
      counties
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteZone = /* GraphQL */ `
  mutation DeleteZone(
    $input: DeleteZoneInput!
    $condition: ModelZoneConditionInput
  ) {
    deleteZone(input: $input, condition: $condition) {
      id
      name
      counties
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createStudentEvent = /* GraphQL */ `
  mutation CreateStudentEvent(
    $input: CreateStudentEventInput!
    $condition: ModelStudentEventConditionInput
  ) {
    createStudentEvent(input: $input, condition: $condition) {
      id
      studentId
      busId
      chaperoneId
      hotelId
      room
      checkin {
        thursday
        friday
        saturday
        __typename
      }
      signature
      medicalReceived
      notes {
        id
        type
        text
        __typename
      }
      student {
        id
        name {
          first
          last
          preferred
          __typename
        }
        dob
        sex
        gender
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        grade
        school {
          id
          beds
          slug
          createdAt
          updatedAt
          districtSchoolsId
          schoolZoneId
          __typename
        }
        schoolID
        bus {
          id
          name
          capacity
          createdAt
          updatedAt
          __typename
        }
        applications {
          nextToken
          __typename
        }
        invoice {
          id
          number
          amount
          dueAt
          memo
          duplicates
          createdAt
          updatedAt
          invoiceStudentId
          __typename
        }
        eventId
        event {
          id
          studentId
          busId
          chaperoneId
          hotelId
          room
          signature
          medicalReceived
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        busStudentsId
        chaperoneStudentsId
        hotelStudentsId
        studentInvoiceId
        __typename
      }
      bus {
        id
        name
        capacity
        students {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      chaperone {
        id
        name {
          prefix
          first
          last
          __typename
        }
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        role
        room
        hotelId
        hotel {
          id
          name
          medicalSuite
          createdAt
          updatedAt
          __typename
        }
        students {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      hotel {
        id
        name
        phone {
          type
          number
          ext
          __typename
        }
        medicalSuite
        students {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateStudentEvent = /* GraphQL */ `
  mutation UpdateStudentEvent(
    $input: UpdateStudentEventInput!
    $condition: ModelStudentEventConditionInput
  ) {
    updateStudentEvent(input: $input, condition: $condition) {
      id
      studentId
      busId
      chaperoneId
      hotelId
      room
      checkin {
        thursday
        friday
        saturday
        __typename
      }
      signature
      medicalReceived
      notes {
        id
        type
        text
        __typename
      }
      student {
        id
        name {
          first
          last
          preferred
          __typename
        }
        dob
        sex
        gender
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        grade
        school {
          id
          beds
          slug
          createdAt
          updatedAt
          districtSchoolsId
          schoolZoneId
          __typename
        }
        schoolID
        bus {
          id
          name
          capacity
          createdAt
          updatedAt
          __typename
        }
        applications {
          nextToken
          __typename
        }
        invoice {
          id
          number
          amount
          dueAt
          memo
          duplicates
          createdAt
          updatedAt
          invoiceStudentId
          __typename
        }
        eventId
        event {
          id
          studentId
          busId
          chaperoneId
          hotelId
          room
          signature
          medicalReceived
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        busStudentsId
        chaperoneStudentsId
        hotelStudentsId
        studentInvoiceId
        __typename
      }
      bus {
        id
        name
        capacity
        students {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      chaperone {
        id
        name {
          prefix
          first
          last
          __typename
        }
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        role
        room
        hotelId
        hotel {
          id
          name
          medicalSuite
          createdAt
          updatedAt
          __typename
        }
        students {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      hotel {
        id
        name
        phone {
          type
          number
          ext
          __typename
        }
        medicalSuite
        students {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteStudentEvent = /* GraphQL */ `
  mutation DeleteStudentEvent(
    $input: DeleteStudentEventInput!
    $condition: ModelStudentEventConditionInput
  ) {
    deleteStudentEvent(input: $input, condition: $condition) {
      id
      studentId
      busId
      chaperoneId
      hotelId
      room
      checkin {
        thursday
        friday
        saturday
        __typename
      }
      signature
      medicalReceived
      notes {
        id
        type
        text
        __typename
      }
      student {
        id
        name {
          first
          last
          preferred
          __typename
        }
        dob
        sex
        gender
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        grade
        school {
          id
          beds
          slug
          createdAt
          updatedAt
          districtSchoolsId
          schoolZoneId
          __typename
        }
        schoolID
        bus {
          id
          name
          capacity
          createdAt
          updatedAt
          __typename
        }
        applications {
          nextToken
          __typename
        }
        invoice {
          id
          number
          amount
          dueAt
          memo
          duplicates
          createdAt
          updatedAt
          invoiceStudentId
          __typename
        }
        eventId
        event {
          id
          studentId
          busId
          chaperoneId
          hotelId
          room
          signature
          medicalReceived
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        busStudentsId
        chaperoneStudentsId
        hotelStudentsId
        studentInvoiceId
        __typename
      }
      bus {
        id
        name
        capacity
        students {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      chaperone {
        id
        name {
          prefix
          first
          last
          __typename
        }
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        role
        room
        hotelId
        hotel {
          id
          name
          medicalSuite
          createdAt
          updatedAt
          __typename
        }
        students {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      hotel {
        id
        name
        phone {
          type
          number
          ext
          __typename
        }
        medicalSuite
        students {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createBus = /* GraphQL */ `
  mutation CreateBus(
    $input: CreateBusInput!
    $condition: ModelBusConditionInput
  ) {
    createBus(input: $input, condition: $condition) {
      id
      name
      capacity
      students {
        items {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateBus = /* GraphQL */ `
  mutation UpdateBus(
    $input: UpdateBusInput!
    $condition: ModelBusConditionInput
  ) {
    updateBus(input: $input, condition: $condition) {
      id
      name
      capacity
      students {
        items {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteBus = /* GraphQL */ `
  mutation DeleteBus(
    $input: DeleteBusInput!
    $condition: ModelBusConditionInput
  ) {
    deleteBus(input: $input, condition: $condition) {
      id
      name
      capacity
      students {
        items {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createChaperone = /* GraphQL */ `
  mutation CreateChaperone(
    $input: CreateChaperoneInput!
    $condition: ModelChaperoneConditionInput
  ) {
    createChaperone(input: $input, condition: $condition) {
      id
      name {
        prefix
        first
        last
        __typename
      }
      phone {
        type
        number
        ext
        __typename
      }
      email {
        type
        address
        __typename
      }
      role
      room
      hotelId
      hotel {
        id
        name
        phone {
          type
          number
          ext
          __typename
        }
        medicalSuite
        students {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      students {
        items {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateChaperone = /* GraphQL */ `
  mutation UpdateChaperone(
    $input: UpdateChaperoneInput!
    $condition: ModelChaperoneConditionInput
  ) {
    updateChaperone(input: $input, condition: $condition) {
      id
      name {
        prefix
        first
        last
        __typename
      }
      phone {
        type
        number
        ext
        __typename
      }
      email {
        type
        address
        __typename
      }
      role
      room
      hotelId
      hotel {
        id
        name
        phone {
          type
          number
          ext
          __typename
        }
        medicalSuite
        students {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      students {
        items {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteChaperone = /* GraphQL */ `
  mutation DeleteChaperone(
    $input: DeleteChaperoneInput!
    $condition: ModelChaperoneConditionInput
  ) {
    deleteChaperone(input: $input, condition: $condition) {
      id
      name {
        prefix
        first
        last
        __typename
      }
      phone {
        type
        number
        ext
        __typename
      }
      email {
        type
        address
        __typename
      }
      role
      room
      hotelId
      hotel {
        id
        name
        phone {
          type
          number
          ext
          __typename
        }
        medicalSuite
        students {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      students {
        items {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createHotel = /* GraphQL */ `
  mutation CreateHotel(
    $input: CreateHotelInput!
    $condition: ModelHotelConditionInput
  ) {
    createHotel(input: $input, condition: $condition) {
      id
      name
      phone {
        type
        number
        ext
        __typename
      }
      medicalSuite
      students {
        items {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateHotel = /* GraphQL */ `
  mutation UpdateHotel(
    $input: UpdateHotelInput!
    $condition: ModelHotelConditionInput
  ) {
    updateHotel(input: $input, condition: $condition) {
      id
      name
      phone {
        type
        number
        ext
        __typename
      }
      medicalSuite
      students {
        items {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteHotel = /* GraphQL */ `
  mutation DeleteHotel(
    $input: DeleteHotelInput!
    $condition: ModelHotelConditionInput
  ) {
    deleteHotel(input: $input, condition: $condition) {
      id
      name
      phone {
        type
        number
        ext
        __typename
      }
      medicalSuite
      students {
        items {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDev = /* GraphQL */ `
  mutation CreateDev(
    $input: CreateDevInput!
    $condition: ModelDevConditionInput
  ) {
    createDev(input: $input, condition: $condition) {
      id
      name
      year
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDev = /* GraphQL */ `
  mutation UpdateDev(
    $input: UpdateDevInput!
    $condition: ModelDevConditionInput
  ) {
    updateDev(input: $input, condition: $condition) {
      id
      name
      year
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDev = /* GraphQL */ `
  mutation DeleteDev(
    $input: DeleteDevInput!
    $condition: ModelDevConditionInput
  ) {
    deleteDev(input: $input, condition: $condition) {
      id
      name
      year
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEnsembleInstruments = /* GraphQL */ `
  mutation CreateEnsembleInstruments(
    $input: CreateEnsembleInstrumentsInput!
    $condition: ModelEnsembleInstrumentsConditionInput
  ) {
    createEnsembleInstruments(input: $input, condition: $condition) {
      id
      ensembleID
      instrumentID
      ensemble {
        id
        slug
        name
        code
        parts
        instruments {
          nextToken
          __typename
        }
        chair {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        chairId
        hasParts
        hasAlternates
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      instrument {
        id
        slug
        name
        order
        form {
          id
          slug
          name
          type
          createdAt
          updatedAt
          __typename
        }
        ensembles {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        formInstrumentsId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEnsembleInstruments = /* GraphQL */ `
  mutation UpdateEnsembleInstruments(
    $input: UpdateEnsembleInstrumentsInput!
    $condition: ModelEnsembleInstrumentsConditionInput
  ) {
    updateEnsembleInstruments(input: $input, condition: $condition) {
      id
      ensembleID
      instrumentID
      ensemble {
        id
        slug
        name
        code
        parts
        instruments {
          nextToken
          __typename
        }
        chair {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        chairId
        hasParts
        hasAlternates
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      instrument {
        id
        slug
        name
        order
        form {
          id
          slug
          name
          type
          createdAt
          updatedAt
          __typename
        }
        ensembles {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        formInstrumentsId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEnsembleInstruments = /* GraphQL */ `
  mutation DeleteEnsembleInstruments(
    $input: DeleteEnsembleInstrumentsInput!
    $condition: ModelEnsembleInstrumentsConditionInput
  ) {
    deleteEnsembleInstruments(input: $input, condition: $condition) {
      id
      ensembleID
      instrumentID
      ensemble {
        id
        slug
        name
        code
        parts
        instruments {
          nextToken
          __typename
        }
        chair {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        chairId
        hasParts
        hasAlternates
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      instrument {
        id
        slug
        name
        order
        form {
          id
          slug
          name
          type
          createdAt
          updatedAt
          __typename
        }
        ensembles {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        formInstrumentsId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserSchools = /* GraphQL */ `
  mutation CreateUserSchools(
    $input: CreateUserSchoolsInput!
    $condition: ModelUserSchoolsConditionInput
  ) {
    createUserSchools(input: $input, condition: $condition) {
      id
      schoolID
      userID
      school {
        id
        beds
        slug
        name {
          legal
          popular
          __typename
        }
        avatar {
          size
          name
          src
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        zone {
          id
          name
          counties
          createdAt
          updatedAt
          __typename
        }
        district {
          id
          beds
          slug
          createdAt
          updatedAt
          districtZoneId
          __typename
        }
        staff {
          type
          title
          __typename
        }
        users {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        districtSchoolsId
        schoolZoneId
        __typename
      }
      user {
        id
        username
        name {
          first
          last
          preferred
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        schools {
          nextToken
          __typename
        }
        ensembleIds
        instrumentIds
        zoneIds
        avatar {
          size
          name
          src
          __typename
        }
        createdAt
        updatedAt
        districtUsersId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserSchools = /* GraphQL */ `
  mutation UpdateUserSchools(
    $input: UpdateUserSchoolsInput!
    $condition: ModelUserSchoolsConditionInput
  ) {
    updateUserSchools(input: $input, condition: $condition) {
      id
      schoolID
      userID
      school {
        id
        beds
        slug
        name {
          legal
          popular
          __typename
        }
        avatar {
          size
          name
          src
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        zone {
          id
          name
          counties
          createdAt
          updatedAt
          __typename
        }
        district {
          id
          beds
          slug
          createdAt
          updatedAt
          districtZoneId
          __typename
        }
        staff {
          type
          title
          __typename
        }
        users {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        districtSchoolsId
        schoolZoneId
        __typename
      }
      user {
        id
        username
        name {
          first
          last
          preferred
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        schools {
          nextToken
          __typename
        }
        ensembleIds
        instrumentIds
        zoneIds
        avatar {
          size
          name
          src
          __typename
        }
        createdAt
        updatedAt
        districtUsersId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserSchools = /* GraphQL */ `
  mutation DeleteUserSchools(
    $input: DeleteUserSchoolsInput!
    $condition: ModelUserSchoolsConditionInput
  ) {
    deleteUserSchools(input: $input, condition: $condition) {
      id
      schoolID
      userID
      school {
        id
        beds
        slug
        name {
          legal
          popular
          __typename
        }
        avatar {
          size
          name
          src
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        zone {
          id
          name
          counties
          createdAt
          updatedAt
          __typename
        }
        district {
          id
          beds
          slug
          createdAt
          updatedAt
          districtZoneId
          __typename
        }
        staff {
          type
          title
          __typename
        }
        users {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        districtSchoolsId
        schoolZoneId
        __typename
      }
      user {
        id
        username
        name {
          first
          last
          preferred
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        schools {
          nextToken
          __typename
        }
        ensembleIds
        instrumentIds
        zoneIds
        avatar {
          size
          name
          src
          __typename
        }
        createdAt
        updatedAt
        districtUsersId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
