<template>
  <div class="option-image-container">
    <b-form-group :label="label" :label-for="inputId">
      <b-form-input :id="inputId" v-model="value" :disabled="disabled" class="font-small-3" @input="updateValue"></b-form-input>
    </b-form-group>
    <hr>
    <b-form-group label="File" label-for="limit">
      <b-form-file v-model="file"
                   :disabled="disabled"
                   accept="image/*"
                   placeholder="Choose a file or drop it here..."
                   drop-placeholder="Drop file here..." @input="convertLogo()"/>
    </b-form-group>
    <b-form-group>
      <v-select v-model="image"
                :options="options"
                :reduce="option => option.value"
                :disabled="disabled"
                append-to-body
                placeholder="Choose an image..."
                class="font-small-3"
                @input="updateValue" />
    </b-form-group>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import blueWhite from '@/assets/images/logo/logo5-blue-white.svg';
import blueWhiteGold from '@/assets/images/logo/logo5-blue-white-gold.svg';
import blueYellow from '@/assets/images/logo/LOGO2.jpg';

export default {
  name: 'OptionImage',
  components: {
    vSelect
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    inputId: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      file: null,
      image: null,
      options: [
        { label: 'Blue and White', value: blueWhite },
        { label: 'Blue, White and Gold', value: blueWhiteGold },
        { label: 'Blue, Yellow', value: blueYellow },
      ],
    }
  },
  methods: {
    updateValue(newValue) {
      this.$emit('input', newValue);
    },
    async convertLogo() {
      const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
      this.$emit('input', await toBase64(this.file))
    },
  },
}
</script>
