<template>
  <div class="certificate" :class="isHovering(hoverCertificate)" :style="computedStyle(settings.certificate.style)">
    <div class="certificate-body" :class="isHovering(hoverBody)" :style="computedStyle(settings.body.style)">
      <img :src="settings.logo.src"
           :style="computedStyle(settings.logo.style)"
           class="certificate-logo"
           :class="isHovering(hoverLogo)"
           alt="Logo"
      />
      <div class="certificate-title" :class="isHovering(hoverTitle)" :style="computedStyle(settings.title.style)">{{ settings.title.text }}</div>
      <div class="certificate-awarded-to" :class="isHovering(hoverAwardedTo)" :style="computedStyle(settings.awardedTo.style)">
        {{ settings.awardedTo.text }}
      </div>
      <div class="certificate-student" :class="isHovering(hoverStudent)" :style="computedStyle(settings.student.style)">
        <template v-if="studentName">
          {{ studentName.first }} {{ studentName.last }}
        </template>
        <template v-else>
          <span class="text-danger">No Student</span>
        </template>
      </div>
      <div class="certificate-school" :class="isHovering(hoverSchool)" :style="computedStyle(settings.school.style)">
        <template v-if="schoolName">
          <template v-if="settings.school.textFormat === 'name.popular'">
            {{ schoolName.popular }}
          </template>
          <template v-else>
            {{ schoolName.legal }}
          </template>
        </template>
        <template v-else>
          <span class="text-danger">No School</span>
        </template>
      </div>
      <div class="certificate-recognition" :class="isHovering(hoverRecognition)" :style="computedStyle(settings.recognition.style)">
        {{ settings.recognition.text }}
      </div>
      <div class="certificate-ensemble" :class="isHovering(hoverEnsemble)" :style="computedStyle(settings.ensemble.style)">
        <template v-if="ensembleName">
          {{ ensembleName }}
        </template>
        <template v-else>
          <span class="text-danger">No Ensemble</span>
        </template>
      </div>
      <div class="certificate-footer" :class="isHovering(hoverFooter)" :style="computedStyle(settings.footer.style)">
        <div class="certificate-footer-left" :class="isHovering(hoverFooterLeft)" :style="computedStyle(settings.footer.left.style)">
          <div class="certificate-footer-text" :style="computedStyle(settings.footer.left.line1.style)">{{ settings.footer.left.line1.text }}</div>
          <div class="certificate-footer-text" :style="computedStyle(settings.footer.left.line2.style)">{{ settings.footer.left.line2.text }}</div>
        </div>
        <div class="certificate-footer-right" :class="isHovering(hoverFooterRight)" :style="computedStyle(settings.footer.right.style)">
          <div class="certificate-footer-text" :style="computedStyle(settings.footer.right.line1.style)">{{ settings.footer.right.line1.text }}</div>
          <div class="certificate-footer-text" :style="computedStyle(settings.footer.right.line2.style)">{{ settings.footer.right.line2.text }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import styleMixin from '@/mixins/style.mixin';

export default {
  name: 'Certificate',
  mixins: [ styleMixin ],
  props: {
    settings: {
      type: Object,
      default: () => ({}),
    },
    studentName: {
      type: Object,
      default: () => ({ first: '', last: '' }),
    },
    schoolName: {
      type: Object,
      default: () => ({ legal: '', popular: '' }),
    },
    ensembleName: {
      type: String,
      default: '',
    },
    hoverCertificate: {
      type: Boolean,
      default: false,
    },
    hoverBody: {
      type: Boolean,
      default: false,
    },
    hoverLogo: {
      type: Boolean,
      default: false,
    },
    hoverTitle: {
      type: Boolean,
      default: false,
    },
    hoverAwardedTo: {
      type: Boolean,
      default: false,
    },
    hoverStudent: {
      type: Boolean,
      default: false,
    },
    hoverSchool: {
      type: Boolean,
      default: false,
    },
    hoverRecognition: {
      type: Boolean,
      default: false,
    },
    hoverEnsemble: {
      type: Boolean,
      default: false,
    },
    hoverFooter: {
      type: Boolean,
      default: false,
    },
    hoverFooterLeft: {
      type: Boolean,
      default: false,
    },
    hoverFooterRight: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    isHovering(prop) {
      return prop ? 'hover-border' : '';
    },
  },
}
</script>

<style scoped>
.hover-border {
  /*border-style: dashed!important;
  border-color: red!important;*/
  background: rgba(33, 41, 105, 0.12) !important
}

.certificate {
  width: calc(11in - 0.5in); /* 11in is the width of the page, 0.5in is the combined sides of x or y margin */
  max-width: 11in!important;
  height: calc(8.5in - 0.5in); /* 8.5in is the height of the page, 0.5in is the combined sides of x or y margin */
  max-height: 8.5in!important;
  border: 8px solid #212969;
  margin: 0.25in; /* Set margin lower than print value, as we are displaying borders in the preview */
  padding: 0;
  background: #fff;
  color: #000;
  text-align: center;
  box-sizing: border-box;
  page-break-inside: avoid;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.certificate-body {
  border: 3px solid #F1C243;
  height: calc(100% - 2rem);
  width: calc(100% - 2rem);
  margin: auto;
  padding: 0;
  background: #fff;
  color: inherit;
  text-align: center;
  box-sizing: border-box;
  page-break-inside: avoid;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.certificate.certificate-editor .certificate-body * {
  border: 1px dashed #ccc;
}

.certificate-logo {
  width: 1.5in;
  height: 1.5in;
  border-radius: 0%;
  object-fit: cover;
  margin: 1rem auto 1rem auto; /* Raise the logo slightly */
  white-space: nowrap;
  background: #fff;
  color: #212969;
  position: relative;
  display: inline-flex;
  font-size: 1rem;
  vertical-align: middle;
  font-weight: 600;
}

.certificate-title {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.2;
  margin: 1rem;
}

.certificate-awarded-to {
  margin: 1rem;
}

.certificate-student {
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 1.2;
  margin: 1rem;
}

.certificate-school {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.2;
  margin: 1rem;
}

.certificate-recognition {
  font-size: 1.4rem;
  margin: 1rem;
  padding-left: 25%;
  padding-right: 25%;
}

.certificate-ensemble {
  font-size: 1.75rem;
  margin: 1rem;
}

.certificate-footer {
  font-size: 1em;
  font-weight: bold;
  padding: 1rem;
  margin: 0;
  margin-top: auto!important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.certificate-footer-left {
  margin-left: 2rem;
  margin-right: 2rem;
}
.certificate-footer-right {
  margin-left: 2rem;
  margin-right: 2rem;
}
.certificate-footer-title {
  font-size: 1em;
  font-weight: bold;
}
.certificate-footer-text {
  font-size: 1em;
  font-weight: normal;
}


</style>
