<template>
  <page-layout ref="layout" @refresh="refresh">
    <template #breadcrumbs="{ year }">
      <b-breadcrumb-item :text="`Printable - ${year}`" />
      <b-breadcrumb-item text="Certificates" active />
    </template>

    <template #actions="{ state }">
      <b-button v-if="state.editing" variant="outline-primary" size="sm" class="d-inline-flex align-items-center mr-50" @click="state.editing = false">
        <font-awesome-icon icon="fa-solid fa-pen-to-square" class="mr-50" /> Editing
      </b-button>
    </template>

    <template #dropdown-options="{ state }">
      <b-dropdown-item @click="state.editing = !state.editing">
        <font-awesome-icon icon="fa-solid fa-pen-to-square" />
        <span class="align-middle ml-50">Edit</span>
      </b-dropdown-item>
      <b-dropdown-item @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>

    <template #content="{ state }">
      <b-row>
        <b-col cols="3" class="d-print-none">
          <!-- Settings -->
          <certificate-settings
              v-if="state.editing"
              :settings="settings"
              :settings-key="key"
              :has-changed="hasChanges"
              @hover:certificate="(isHovering) => hoverCertificate(isHovering)"
              @hover:body="(isHovering) => hoverBody(isHovering)"
              @hover:logo="(isHovering) => hoverLogo(isHovering)"
              @hover:title="(isHovering) => hoverTitle(isHovering)"
              @hover:awardedTo="(isHovering) => hoverAwardedTo(isHovering)"
              @hover:student="(isHovering) => hoverStudent(isHovering)"
              @hover:school="(isHovering) => hoverSchool(isHovering)"
              @hover:recognition="(isHovering) => hoverRecognition(isHovering)"
              @hover:ensemble="(isHovering) => hoverEnsemble(isHovering)"
              @hover:footer="(isHovering) => hoverFooter(isHovering)"
              @hover:footer-left="(isHovering) => hoverFooterLeft(isHovering)"
              @hover:footer-right="(isHovering) => hoverFooterRight(isHovering)"
              @revert="settings = JSON.parse(JSON.stringify(initialSettings))"
              @updated="syncInitialSettings"
          />

          <!-- Filters/Sorting/Paging -->
          <template v-else>
            <!-- Filters -->
            <b-card-actions title="Filters" action-collapse card-class="mb-1" header-class="py-50">
              <b-form-group label="School" label-for="schools">
                <v-select id="schools"
                          v-model="filters.schools.value" label="label"
                          :options="filters.schools.items"
                          :loading="filters.schools.loading"
                          :reduce="option => option.value"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          class="font-small-3">
                  <template #option="{ label, count }">
                    <div class="d-flex justify-content-between">
                      <span> {{ label }} </span>
                      <b-badge size="sm" variant="light-primary" title="Letters">{{ count }}</b-badge>
                    </div>
                  </template>
                  <template #selected-option="{ label }">
                    {{ label }}
                  </template>
                </v-select>
              </b-form-group>

              <b-form-group label="Ensemble" label-for="ensembles">
                <v-select id="ensembles"
                          v-model="filters.ensembles.value"
                          :options="filters.ensembles.items"
                          :loading="filters.ensembles.loading"
                          :reduce="option => option.value"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          class="font-small-3">
                  <template #option="{ label, count }">
                    <div class="d-flex justify-content-between">
                      <span> {{ label }} </span>
                      <b-badge size="sm" variant="light-primary" title="Letters">{{ count }}</b-badge>
                    </div>
                  </template>
                  <template #selected-option="{ label }">
                    {{ label }}
                  </template>
                </v-select>
              </b-form-group>

              <b-form-group label="Instrument" label-for="instruments">
                <v-select id="instruments"
                          v-model="filters.instruments.value"
                          :options="filters.instruments.items"
                          :loading="filters.instruments.loading"
                          :reduce="option => option.value"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          class="font-small-3">
                  <template #option="{ label, count }">
                    <div class="d-flex justify-content-between">
                      <span> {{ label }} </span>
                      <b-badge size="sm" variant="light-primary" title="Letters">{{ count }}</b-badge>
                    </div>
                  </template>
                  <template #selected-option="{ label }">
                    {{ label }}
                  </template>
                </v-select>
              </b-form-group>

              <b-form-group label="Student" label-for="students">
                <v-select id="students"
                          v-model="filters.students.value"
                          :options="filters.students.items"
                          :loading="filters.students.loading"
                          :reduce="option => option.value"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          class="font-small-3">
                  <template #option="{ label, count }">
                    <div class="d-flex justify-content-between">
                      <span> {{ label }} </span>
                      <b-badge size="sm" variant="light-primary" title="Letters">{{ count }}</b-badge>
                    </div>
                  </template>
                  <template #selected-option="{ label }">
                    {{ label }}
                  </template>
                </v-select>
              </b-form-group>

              <b-form-group label="Teacher" label-for="teachers">
                <v-select id="teachers"
                          v-model="filters.teachers.value"
                          :options="filters.teachers.items"
                          :loading="filters.teachers.loading"
                          :reduce="option => option.value"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          class="font-small-3">
                  <template #option="{ label, count }">
                    <div class="d-flex justify-content-between">
                      <span> {{ label }} </span>
                      <b-badge size="sm" variant="light-primary" title="Letters">{{ count }}</b-badge>
                    </div>
                  </template>
                  <template #selected-option="{ label }">
                    {{ label }}
                  </template>
                </v-select>
              </b-form-group>
            </b-card-actions>
            <!-- Sorting -->
            <b-card-actions title="Sorting" action-collapse card-class="mb-1" header-class="py-50">
              <b-form-group label="Sort By" label-for="sorting-by">
                <b-input-group class="flex-nowrap">
                  <template v-if="sorting.drag">
                    <draggable v-model="sorting.by" class="p-50 flex-grow-1 rounded-left sorting-btn-border">
                      <b-badge v-for="(item, index) in sorting.by" :key="index" variant="light-primary" class="mr-50 font-small-3 font-weight-normal cursor-move">
                        {{ sorting.options.find(option => option.value === item).label }}
                        <font-awesome-icon :icon="['fas', 'grip-vertical']" class="my-auto ml-25"/>
                      </b-badge>
                    </draggable>
                  </template>
                  <template v-else>
                    <v-select v-model="sorting.by" multiple
                              :options="sorting.options" label="label"
                              :reduce="option => option.value"
                              input-id="sorting-by" class="h-100"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"/>
                  </template>

                  <b-input-group-append>
                    <b-button v-if="sorting.by.length > 1" size="sm" variant="white" class="sorting-btn-border border-left-0" @click="sorting.drag = !sorting.drag" title="Selected Order">
                      <font-awesome-icon :icon="['fas', 'grip-vertical']" />
                    </b-button>
                    <b-button size="sm" variant="white" class="sorting-btn-border" @click="sorting.desc = !sorting.desc" title="Sort Order">
                      <font-awesome-icon :icon="['fas', sorting.desc ? 'sort-alpha-down-alt' : 'sort-alpha-down']" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-card-actions>
            <!-- Paging -->
            <b-card-actions title="Paging" action-collapse card-class="mb-1" header-class="py-50">
              <b-form-group label="Page Size" label-for="paging-size">
                <v-select id="paging-size"
                          v-model="paging.size"
                          :options="paging.sizes"
                          :reduce="option => option.value"
                          :clearable="false"
                          append-to-body/>
              </b-form-group>

              <b-pagination v-if="paging.size !== -1"
                            id="paging-page"
                            v-model="paging.page"
                            :total-rows="paging.total"
                            :per-page="paging.size"
                            first-number
                            last-number
                            :limit="3"
                            pills align="fill"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="14"/>
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="14"/>
                </template>
              </b-pagination>
            </b-card-actions>
            <!-- Print -->
            <b-button block variant="primary" @click="printWindow()">
              <font-awesome-icon icon="fa-solid fa-print" />
              <span class="align-middle ml-50">Print</span>
            </b-button>
          </template>
        </b-col>
        <b-col cols="9">
					<!-- Alerts -->
          <b-alert :show="hasChanges" variant="danger" class="d-print-none">
            <div class="d-flex">
              <b-icon icon="exclamation-triangle-fill" font-scale="1" class="mt-25 mr-25"/>
              <div class="align-start ml-50 font-small-3">
                <strong class="d-block">Unsaved Changed!</strong>
                <span>Don't forget to save your changes before leaving the page.</span>
              </div>
            </div>
          </b-alert>
          <b-alert :show="isContentScaled" variant="primary" dismissible class="d-print-none">
            <b-icon icon="exclamation-triangle-fill" font-scale="1"></b-icon>
            <span class="align-middle ml-50 font-small-3">
              The content has been scaled down to fit the page.
            </span>
          </b-alert>
          <b-alert :show="state.editing" variant="primary" dismissible class="d-print-none">
            <b-icon icon="exclamation-triangle-fill" font-scale="1"></b-icon>
            <span class="align-middle ml-50 font-small-3">
              While in edit mode, each configurable area will have a dashed border around it to help guide you.
            </span>
          </b-alert>

					<!-- Content -->
          <b-overlay :show="selections.loading" variant="white" opacity="1" :rounded="true">
              <template #overlay>
                <div class="d-flex align-items-center flex-column">
                  <b-spinner small label="Loading..."></b-spinner>
                  <small class="mt-50">Loading...</small>
                </div>
              </template>
              <template #default>
                <template v-if="selections.loading">
                  <b-card><b-card-body></b-card-body></b-card>
                </template>
                <template v-else>
                  <b-alert v-if="selections.items.length === 0" show variant="danger">
                    <b-icon icon="exclamation-triangle-fill" font-scale="1" class="mr-50"></b-icon>
                    <span class="align-middle ml-50 font-small-3">
                    No selections were found.
                  </span>
                  </b-alert>
                  <b-alert v-else-if="filteredSelections.length === 0" show variant="primary" class="d-print-none">
                    <b-icon icon="info-circle-fill" font-scale="1"></b-icon>
                    <span class="align-middle ml-50 font-small-3">
                      No selections found
                    </span>
                  </b-alert>
                  <template v-else>
                    <div class="certificate-container">
                      <template v-if="state.editing">
                        <certificate :settings="settings"
                                     :student-name="{ first: 'John', last: 'Doe' }"
                                     :school-name="{ legal: 'School Legal Name', popular: 'School Popular Name' }"
                                     :ensemble-name="'Ensemble Name'"
                                     :hover-certificate="hover.certificate"
                                     :hover-body="hover.body"
                                     :hover-logo="hover.logo"
                                     :hover-title="hover.title"
                                     :hover-awarded-to="hover.awardedTo"
                                     :hover-student="hover.student"
                                     :hover-school="hover.school"
                                     :hover-recognition="hover.recognition"
                                     :hover-ensemble="hover.ensemble"
                                     :hover-footer="hover.footer"
                                     :hover-footer-left="hover.footerLeft"
                                     :hover-footer-right="hover.footerRight"
                                     class="certificate-editor"/>
                      </template>
                      <template v-else>
                        <certificate v-for="(selection, index) in filteredSelections" :key="index"
                                     :settings="settings"
                                     :student-name="hasValue(selection, 'application.student.name') ? selection.application.student.name : null"
                                     :school-name="hasValue(selection, 'application.student.school.name') ? selection.application.student.school.name : null"
                                     :ensemble-name="hasValue(selection, 'ensemble.name') ? selection.ensemble.name : null"/>
                      </template>
                    </div>
                    <div class="certificate-container-paging d-flex align-items-center justify-content-center d-print-none w-100">
                      <span v-if="selections.items.length > 0" class="text-muted">Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.of }} Certificates</span>
                      <span v-else class="text-muted">Showing 0 of {{ pagination.of }} Certificates</span>
                    </div>
                  </template>

                </template>

              </template>
            </b-overlay>
        </b-col>
      </b-row>
    </template>

    <template #debug>
      <b-row>
        <b-col cols="6">
          <debug title="Settings">
            {{ settings }}
          </debug>
        </b-col>
        <b-col cols="6">
          <debug title="Settings To Be Saved">
            {{ JSON.stringify(filterObject(settings), null, 2) }}
          </debug>
        </b-col>
      </b-row>

    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout.vue';
import vSelect from 'vue-select'
import Fuse from 'fuse.js';
import {API, graphqlOperation} from 'aws-amplify';
import {listSelections} from '@/graphql/queries/certificates';
import _ from 'lodash';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import Certificate from './Certificate.vue';
import SettingsContainer from '../settings/SettingsContainer.vue';
import OptionsSyncContainer from '../settings/OptionsSyncContainer.vue';
import OptionsHeader from '../settings/OptionsHeader.vue';
import OptionColor from '../settings/OptionColor.vue';
import OptionImage from '../settings/OptionImage.vue';
import OptionSelect from '../settings/OptionSelect.vue';
import OptionValueUnit from '../settings/OptionValueUnit.vue';

import printableSettingsMixin from '../settings/settings.mixin';
import settingsMixin from '@/mixins/settings.mixin';
import printMixin from '../print.mixin';
import lodashMixin from '@/mixins/lodash.mixin';
import draggable from 'vuedraggable';

import CertificateSettings from '@/views/printable/certificates/CertificateSettings.vue';

export default {
  components: {
    CertificateSettings,
    SettingsContainer,
    PageLayout,
    Certificate,
    OptionSelect,
    OptionValueUnit,
    OptionColor,
    OptionImage,
    OptionsHeader,
    OptionsSyncContainer,
    BCardActions,
    vSelect,
    draggable
  },
  mixins: [ printableSettingsMixin, printMixin, lodashMixin, settingsMixin ],
  data() {
    return {
      key: 'certificates',
      settings: {
        certificate: {
          style: {
            borderColor: null,
            borderStyle: null,
            borderWidth: {
              value: null,
              unit: null
            },
            width: null,
            height: null,
            margin: {
              value: null,
              unit: null
            },
            paddingTop: {
              value: null,
              unit: null
            },
            paddingBottom: {
              value: null,
              unit: null
            },
            paddingLeft: {
              value: null,
              unit: null
            },
            paddingRight: {
              value: null,
              unit: null
            },
          },
        },
        body: {
          style: {
            color: null,
            fontFamily: null,
            fontWeight: null,
            borderColor: null,
            borderStyle: null,
            borderWidth: {
              value: null,
              unit: null
            },
            paddingTop: {
              value: null,
              unit: null
            },
            paddingBottom: {
              value: null,
              unit: null
            },
            paddingLeft: {
              value: null,
              unit: null
            },
            paddingRight: {
              value: null,
              unit: null
            },
          }
        },
        logo: {
          src: null,
          style: {
            width: {
              value: null,
              unit: null
            },
            height: {
              value: null,
              unit: null
            },
            borderWidth: {
              value: null,
              unit: null
            },
            borderStyle: null,
            borderColor: null,
            borderRadius: null,
            objectFit: null,
            marginTop: {
              value: null,
              unit: null
            },
            marginBottom: {
              value: null,
              unit: null
            },
          }
        },
        title: {
          text: 'New York State School Music Association',
          style: {
            fontSize: {
              value: null,
              unit: null
            },
            fontFamily: null,
            fontWeight: null,
            marginTop: {
              value: null,
              unit: null
            },
            marginBottom: {
              value: null,
              unit: null
            },
            marginLeft: {
              value: null,
              unit: null
            },
            marginRight: {
              value: null,
              unit: null
            },
            paddingTop: {
              value: null,
              unit: null
            },
            paddingBottom: {
              value: null,
              unit: null
            },
            paddingLeft: {
              value: null,
              unit: null
            },
            paddingRight: {
              value: null,
              unit: null
            },
          }
        },
        awardedTo: {
          text: 'Award Presented to:',
          style: {
            fontSize: {
              value: null,
              unit: null
            },
            fontFamily: null,
            fontWeight: null,
            marginTop: {
              value: null,
              unit: null
            },
            marginBottom: {
              value: null,
              unit: null
            },
            marginLeft: {
              value: null,
              unit: null
            },
            marginRight: {
              value: null,
              unit: null
            },
            paddingTop: {
              value: null,
              unit: null
            },
            paddingBottom: {
              value: null,
              unit: null
            },
            paddingLeft: {
              value: null,
              unit: null
            },
            paddingRight: {
              value: null,
              unit: null
            },
          }
        },
        student: {
          text: '',
          style: {
            fontSize: {
              value: null,
              unit: null
            },
            fontFamily: null,
            fontWeight: null,
            marginTop: {
              value: null,
              unit: null
            },
            marginBottom: {
              value: null,
              unit: null
            },
            marginLeft: {
              value: null,
              unit: null
            },
            marginRight: {
              value: null,
              unit: null
            },
            paddingTop: {
              value: null,
              unit: null
            },
            paddingBottom: {
              value: null,
              unit: null
            },
            paddingLeft: {
              value: null,
              unit: null
            },
            paddingRight: {
              value: null,
              unit: null
            },
          }
        },
        school: {
          textFormat: '',
          style: {
            fontSize: {
              value: null,
              unit: null
            },
            fontFamily: null,
            fontWeight: null,
            marginTop: {
              value: null,
              unit: null
            },
            marginBottom: {
              value: null,
              unit: null
            },
            marginLeft: {
              value: null,
              unit: null
            },
            marginRight: {
              value: null,
              unit: null
            },
            paddingTop: {
              value: null,
              unit: null
            },
            paddingBottom: {
              value: null,
              unit: null
            },
            paddingLeft: {
              value: null,
              unit: null
            },
            paddingRight: {
              value: null,
              unit: null
            },
          }
        },
        ensemble: {
          text: null,
          style: {
            fontSize: {
              value: null,
              unit: null
            },
            fontFamily: null,
            fontWeight: null,
            marginTop: {
              value: null,
              unit: null
            },
            marginBottom: {
              value: null,
              unit: null
            },
            marginLeft: {
              value: null,
              unit: null
            },
            marginRight: {
              value: null,
              unit: null
            },
            paddingTop: {
              value: null,
              unit: null
            },
            paddingBottom: {
              value: null,
              unit: null
            },
            paddingLeft: {
              value: null,
              unit: null
            },
            paddingRight: {
              value: null,
              unit: null
            },
          }
        },
        recognition: {
          text: 'In recognition of your selection for the Conference All-State Performance Group',
          style: {
            fontSize: {
              value: null,
              unit: null
            },
            fontFamily: null,
            fontWeight: null,
            marginTop: {
              value: null,
              unit: null
            },
            marginBottom: {
              value: null,
              unit: null
            },
            marginLeft: {
              value: null,
              unit: null
            },
            marginRight: {
              value: null,
              unit: null
            },
            paddingTop: {
              value: null,
              unit: null
            },
            paddingBottom: {
              value: null,
              unit: null
            },
            paddingLeft: {
              value: null,
              unit: null
            },
            paddingRight: {
              value: null,
              unit: null
            },
          }
        },
        footer: {
          style: {
            display: null,
            justifyContent: null,
            alignItems: null,
            marginBottom: {
              value: null,
              unit: null
            },
            marginLeft: {
              value: null,
              unit: null
            },
            marginRight: {
              value: null,
              unit: null
            },
            paddingTop: {
              value: null,
              unit: null
            },
            paddingBottom: {
              value: null,
              unit: null
            },
            paddingLeft: {
              value: null,
              unit: null
            },
            paddingRight: {
              value: null,
              unit: null
            },
          },
          left: {
            line1: {
              text: 'Performance',
              style: {
                color: null,
                fontFamily: null,
                fontSize: {
                  value: null,
                  unit: null
                },
                fontWeight: null,
              }
            },
            line2: {
              text: `All-State ${new Date().getFullYear()}`,
              style: {
                color: null,
                fontFamily: null,
                fontSize: {
                  value: null,
                  unit: null
                },
                fontWeight: null,
              }
            },
            style: {
              marginLeft: {
                value: null,
                unit: null
              },
              marginRight: {
                value: null,
                unit: null
              },
            }
          },
          right: {
            line1: {
              text: 'Signature',
              style: {
                color: null,
                fontFamily: null,
                fontSize: {
                  value: null,
                  unit: null
                },
                fontWeight: null,
              }
            },
            line2: {
              text: 'President',
              style: {
                color: null,
                fontFamily: null,
                fontSize: {
                  value: null,
                  unit: null
                },
                fontWeight: null,
              }
            },
            style: {
              marginLeft: {
                value: null,
                unit: null
              },
              marginRight: {
                value: null,
                unit: null
              },
            }
          }
        },
      },
      options: {
        align: [
          { value: 'start', label: 'Start' },
          { value: 'center', label: 'Center' },
          { value: 'end', label: 'End' },
        ],
        borderStyle: [
          { value: 'none', label: 'None'},
          { value: 'solid', label: 'Solid'},
          { value: 'dashed', label: 'Dashed'},
          { value: 'dotted', label: 'Dotted'},
          { value: 'double', label: 'Double'},
          { value: 'groove', label: 'Groove'},
          { value: 'ridge', label: 'Ridge'},
          { value: 'inset', label: 'Inset'},
          { value: 'outset', label: 'Outset'},
          { value: 'hidden', label: 'Hidden'},
        ],
        display: [
          { value: 'flex', label: 'Flex' },
          { value: 'block', label: 'Block' },
          { value: 'inline-block', label: 'Inline Block' },
          { value: 'inline', label: 'Inline' },
          { value: 'none', label: 'None' },
        ],
        fontFamily: [
          { value: 'Arial', label: 'Arial' },
          { value: 'Georgia', label: 'Georgia' },
          { value: 'Times New Roman', label: 'Times New Roman' },
          { value: 'Courier New', label: 'Courier New' },
          { value: 'Montserrat', label: 'Montserrat' },
          { value: 'Helvetica', label: 'Helvetica' },
          { value: 'serif', label: 'Serif' },
          { value: 'Cedarville Cursive', label: 'Cedarville Cursive' },
          { value: 'Freehand', label: 'Freehand' },
          { value: 'Kristi', label: 'Kristi' },
          { value: 'La Belle Aurore', label: 'La Belle Aurore' },
          { value: 'Pinyon Script', label: 'Pinyon Script' },
          { value: 'Sacramento', label: 'Sacramento' },
          { value: 'Satisfy', label: 'Satisfy' },
        ],
        fontWeight: [
            { value: '100', label: 'Thin' },
            { value: '200', label: 'Extra Light' },
            { value: '300', label: 'Light' },
            { value: '400', label: 'Normal' },
            { value: '500', label: 'Medium' },
            { value: '600', label: 'Semi Bold' },
            { value: '700', label: 'Bold' },
            { value: '800', label: 'Extra Bold' },
            { value: '900', label: 'Black' },
        ],
        justify: [
          { value: 'flex-start', label: 'Start' },
          { value: 'flex-end', label: 'End' },
          { value: 'center', label: 'Center' },
          { value: 'space-between', label: 'Space Between' },
          { value: 'space-around', label: 'Space Around' },
          { value: 'space-evenly', label: 'Space Evenly' },
        ],
        fit: [
          { value: 'fill', label: 'Fill' },
          { value: 'contain', label: 'Contain' },
          { value: 'cover', label: 'Cover' },
          { value: 'none', label: 'None' },
          { value: 'scale-down', label: 'Scale Down' },
        ],
        radius: [
          { value: '50%', label: 'Round' },
          { value: '0%', label: 'Square' }
        ],

      },
      filters: {
        schools: {
          value: null,
          items: [],
          loading: true
        },
        ensembles: {
          value: null,
          items: [],
          loading: true
        },
        instruments: {
          value: null,
          items: [],
          loading: true
        },
        students: {
          value: null,
          items: [],
          loading: true
        },
        teachers: {
          value: null,
          items: [],
          loading: true
        }
      },
      sorting: {
        by: ['application.student.school.name.legal', 'application.student.name.first', 'application.student.name.last'],
        desc: false,
        drag: false,
        options: [
          { label: 'School', value: 'application.student.school.name.legal'},
          { label: 'First Name', value: 'application.student.name.first'},
          { label: 'Last Name', value: 'application.student.name.last'},
        ]
      },
      paging: {
        total: 0,
        page: 1,
        size: 25,
        sizes: [
          { value: -1, label: 'All' },
          { value: 1, label: '1' },
          { value: 5, label: '5' },
          { value: 10, label: '10' },
          { value: 25, label: '25' },
          { value: 50, label: '50' },
          { value: 100, label: '100' }
        ]
      },
      hover: {
        certificate: false,
        body: false,
        logo: false,
        title: false,
        awardedTo: false,
        student: false,
        school: false,
        recognition: false,
        ensemble: false,
        footer: false,
        footerLeft: false,
        footerRight: false,
      },
      selections: {
        items: [],
        loading: true,
      },
    };
  },
  computed: {
    filteredSelections() {
      const fuse = new Fuse(this.selections.items, {
        useExtendedSearch: true,
        threshold: 0.3,
        keys: [
          'application.student.school.id',
          'ensemble.id',
          'application.instrument.id',
          'application.student.id',
          'application.teacher.id'
        ]
      })
      const query = {$and: []}
      if (this.filters.schools.value) {
        query.$and.push({'application.student.school.id': this.filters.schools.value})
      }
      if (this.filters.ensembles.value) {
        query.$and.push({'ensemble.id': this.filters.ensembles.value})
      }
      if (this.filters.instruments.value) {
        query.$and.push({'application.instrument.id': this.filters.instruments.value})
      }
      if (this.filters.students.value) {
        query.$and.push({'application.student.id': this.filters.students.value})
      }
      if (this.filters.teachers.value) {
        query.$and.push({'application.teacher.id': this.filters.teachers.value})
      }

      let {items} = this.selections
      if (query.$and.length) {
        items = fuse.search(query).map(({item}) => item)
      }

      items = this.sortItems(items)

      if(this.paging.size >= 1) {
        this.paging.total = items.length // eslint-disable-line vue/no-side-effects-in-computed-properties
        const chunks = _.chunk(items, this.paging.size)
        return chunks[this.paging.page - 1] || []
      }
      return items
    },
    pagination() {
      let to = this.paging.size * (this.paging.page)
      if(this.paging.size === 0 || to >= this.paging.total) {
        to = this.paging.total
      }
      let from = 0
      if(this.paging.total > 0) {
        from = this.paging.size * (this.paging.page - 1) + 1
      }

      return {
        from: from,
        to: to,
        of: this.paging.total,
      }
    },
  },
  async mounted() {
    this.updateContentScaleWidth(1600) // print.mixin property used to determine if the content has been scaled
    const settings = await this.getSettings(this.key)
    if(settings) {
      this.settings = this.deepMerge(this.settings, settings);
    }
    if(this.settings.logo.src === null) {
      // eslint-disable-next-line global-require
      this.settings.logo.src = require('@/assets/images/logo/LOGO2.jpg')
    }
    this.initialSettings = _.cloneDeep(this.settings)

    await this.listSelections()
    this.$refs.layout.state.loading = false
  },
  methods: {
    refresh() {
      this.selections.loading = true
      this.filters.schools.loading = true
      this.filters.schools.value = null
      this.filters.ensembles.loading = true
      this.filters.ensembles.value = null
      this.filters.instruments.loading = true
      this.filters.instruments.value = null
      this.filters.students.loading = true
      this.filters.students.value = null
      this.filters.teachers.loading = true
      this.filters.teachers.value = null
      this.listSelections()
    },
    async listSelections(nextToken, pagedSelections) {
      const selections = pagedSelections || []
      const input = {
        limit: 500,
        filter: {
          accepted: { eq: true },
          createdAt: {
            between: [
              this.settingsStore.app.current.year.start,
              this.settingsStore.app.current.year.end
            ]
          }
        },
        nextToken: nextToken,
      }
      const response = await API.graphql(graphqlOperation(listSelections, input));

      selections.push(...response.data.listSelections.items);

      if(response.data.listSelections.nextToken) {
        await this.listSelections(response.data.listSelections.nextToken, selections)
      }
      else {
        this.selections.items = selections
        this.selections.loading = false

        this.filters.schools.items = _.uniqBy(this.selections.items.filter(item => item.application?.student?.school?.id).map(item => ({
          value: item?.application?.student?.school?.id,
          label: item?.application?.student?.school?.name?.legal,
          count: this.selections.items.filter(selection => selection?.application?.student?.school?.id === item.application?.student?.school?.id)?.length || 0
        })), 'value').sort((a, b) => a.label.localeCompare(b.label));
        this.filters.schools.loading = false

        this.filters.ensembles.items = _.uniqBy(this.selections.items.filter(item => item?.ensemble?.id).map(item => ({
          value: item?.ensemble?.id,
          label: item?.ensemble?.name,
          count: this.selections.items.filter(selection => selection?.ensemble?.id === item?.ensemble?.id)?.length || 0
        })), 'value').sort((a, b) => a.label.localeCompare(b.label));
        this.filters.ensembles.loading = false

        this.filters.instruments.items = _.uniqBy(this.selections.items.filter(item => item?.application?.instrument?.id).map(item => ({
          value: item?.application?.instrument?.id,
          label: item?.application?.instrument?.name,
          count: this.selections.items.filter(selection => selection?.application?.instrument?.id === item?.application?.instrument?.id)?.length || 0
        })), 'value').sort((a, b) => a.label.localeCompare(b.label));
        this.filters.instruments.loading = false

        this.filters.students.items = _.uniqBy(this.selections.items.filter(item => item.application?.student?.id).map(item => ({
          value: item?.application?.student?.id,
          label: `${item?.application?.student?.name?.first} ${item?.application?.student?.name?.last}`,
          count: this.selections.items.filter(selection => selection?.application?.student?.id === item?.application?.student?.id)?.length || 0
        })), 'value').sort((a, b) => a.label.localeCompare(b.label));
        this.filters.students.loading = false

        this.filters.teachers.items = _.uniqBy(this.selections.items.filter(item => item.application?.teacher?.id).map(item => ({
          value: item?.application?.teacher?.id,
          label: `${item?.application?.teacher?.name?.first} ${item?.application?.teacher?.name?.last}`,
          count: this.selections.items.filter(selection => selection?.application?.teacher?.id === item?.application?.teacher?.id)?.length || 0
        })), 'value').sort((a, b) => a.label.localeCompare(b.label));
        this.filters.teachers.loading = false
      }
    },

    /** Sorting **/
    sortItems(items) {
      const { by, desc } = this.sorting;
      const order = desc ? 'desc' : 'asc';
      const orders = []; // Array to hold the order (asc/desc) for each criteria

      for (let i = 0; i < by.length; i++) {
        orders.push(order);
      }

      return _.orderBy(items, by, orders);
    },

    /** Hover **/
    hoverCertificate(isHovered) {
      this.hover.certificate = isHovered
    },
    hoverBody(isHovered) {
      this.hover.body = isHovered
    },
    hoverLogo(isHovered) {
      this.hover.logo = isHovered
    },
    hoverTitle(isHovered) {
      this.hover.title = isHovered
    },
    hoverAwardedTo(isHovered) {
      this.hover.awardedTo = isHovered
    },
    hoverStudent(isHovered) {
      this.hover.student = isHovered
    },
    hoverSchool(isHovered) {
      this.hover.school = isHovered
    },
    hoverRecognition(isHovered) {
      this.hover.recognition = isHovered
    },
    hoverEnsemble(isHovered) {
      this.hover.ensemble = isHovered
    },
    hoverFooter(isHovered) {
      this.hover.footer = isHovered
    },
    hoverFooterLeft(isHovered) {
      this.hover.footerLeft = isHovered
    },
    hoverFooterRight(isHovered) {
      this.hover.footerRight = isHovered
    },

  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Freehand&family=Kristi&family=La+Belle+Aurore&family=Pinyon+Script&family=Sacramento&family=Satisfy&display=swap');
@import './certificates.css';
</style>
