import _ from 'lodash';

export default {
    methods: {
        hasValue(obj, path) {
            const value = _.get(obj, path);
            return value !== null && value !== undefined;
        }
    }
}
