<template>
  <b-form-group>
    <template #label>
      <div class="d-flex justify-content-between align-items-center">
        <label :for="inputId">{{ label }}</label>
        <b-button size="sm" variant="outline-primary" :disabled="disabled" @click="clearColor">Unset</b-button>
      </div>
    </template>
    <sketch-picker
      :id="inputId"
      :value="color"
      :disabled="disabled"
      :preset-colors=" [
        '#212969', '#F1C243', '#28c76f', '#00cfe8',
        '#ff9f43', '#ea5455', '#f6f6f6', '#4b4b4b',
        '#000000', '#4A4A4A', '#9B9B9B', '#FFFFFF',
        'rgba(0,0,0,0)'
      ]"
      class="sketch-picker"
      @input="updateColor" />
  </b-form-group>
</template>

<script>
import { Sketch } from 'vue-color';

export default {
  name: 'CustomColorPicker',
  components: {
    'sketch-picker': Sketch,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    inputId: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      color: this.value || 'fff',
      colorKeyword: '',
      showPicker: true,
    };
  },
  methods: {
    updateColor(color) {
      const rgbaColor = `rgba(${color.rgba.r}, ${color.rgba.g}, ${color.rgba.b}, ${color.rgba.a})`;
      this.color = rgbaColor;
      this.colorKeyword = '';
      this.$emit('input', rgbaColor);
    },
    clearColor() {
      this.color = '#000';
      this.$emit('input', null);
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-color-picker {
  display: inline-flex;
  align-items: center;
}


::v-deep {

}

</style>

<!--
<template>
  <b-form-group :label="label" :label-for="inputId">
    <b-form-input :id="inputId" :value="value" type="color" @input="updateValue" />
  </b-form-group>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    inputId: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
  },
  methods: {
    updateValue(newValue) {
      this.$emit('input', newValue);
    },
  },
};
</script>
-->
