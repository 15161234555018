<template>
	<b-form-group :label="label" label-for="input-id">
		<v-select v-model="internalValue"
              :options="options"
              :disabled="disabled"
              :input-id="inputId"
              :placeholder="placeholder"
              :append-to-body="true"
              :taggable="taggable"
              :reduce="reduce"
              :select-on-tab="true"
              class="font-small-3 h-100" @input="updateValue" />
	</b-form-group>
</template>

<script>
import vSelect from 'vue-select';

export default {
  name: 'OptionSelect',
  components: {
      vSelect
  },
  props: {
    label: {
        type: String,
        required: true,
    },
    inputId: {
        type: String,
        required: true,
    },
    value: {
        type: String,
        default: null
    },
    options: {
        type: Array,
        required: true,
    },
    placeholder: {
        type: String,
        default: null,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    taggable: {
      type: Boolean,
      default: false
    },
    reduce: {
      type: Function,
      default: option => option.value
    }
  },
  data() {
    return {
      internalValue: this.value,
    };
  },
  watch: {
    value(newVal) {
      this.internalValue = newVal;
    },
    internalValue(newVal) {
      if (newVal !== this.value) {
        this.updateValue(newVal);
      }
    },
  },
  methods: {
    updateValue(newValue) {
      this.$emit('input', newValue);
    },
  },
};
</script>
