<template>
  <b-form-group :label="label" :label-for="inputId">
    <b-input-group class="input-group-merge">
      <b-form-input :id="inputId" :value="value.value" type="number" number :step="step" :placeholder="placeholder" :disabled="disabled" class="font-small-3" @input="updateValue" />
      <b-input-group-append class="">
        <b-dropdown
            variant="outline-transparent"
            right
            no-flip
            boundary="window"
            :disabled="disabled"
            toggle-class="px-75 d-inline-flex align-items-center font-small-3 bg-white"
            :popper-opts="{ positionFixed: true }"
        >
          <template #button-content>
            <span class="px-25">{{ value.unit ? value.unit : 'Unit' }}</span>
            <feather-icon icon="ChevronDownIcon" />
          </template>
          <b-dropdown-item-button
              v-for="(unit, index) in units"
              :key="index"
              button-class="w-100"
              :disabled="disabled"
              @click="updateUnit(unit.value)"
          >
            {{ unit.label }}
          </b-dropdown-item-button>
          <b-dropdown-divider />
          <b-dropdown-item-button button-class="w-100" :disabled="disabled" @click="updateUnit(null)">
            null
          </b-dropdown-item-button>
        </b-dropdown>
      </b-input-group-append>
    </b-input-group>
  </b-form-group>
</template>

<script>
import optionSyncItem from './option-sync-item.mixin'

export default {
  name: 'OptionValueUnit',
  mixins: [optionSyncItem],
  props: {
    label: {
      type: String,
      required: true,
    },
    inputId: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    placeholder: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      units: [
        { label: '%', value: '%' },
        { label: 'px', value: 'px' },
        { label: 'pt', value: 'pt' },
        { label: 'em', value: 'em' },
        { label: 'rem', value: 'rem' },
        { label: 'cm', value: 'cm' },
        { label: 'mm', value: 'mm' },
        { label: 'in', value: 'in' },
      ],
    }
  },
  computed: {
    step() {
      const value = parseFloat(this.value?.value);
      if (Number.isNaN(value) || Number.isInteger(value) || value === null) {
        return 1
      }
      return 0.1;
    },
  },

  // The component's methods include `updateValue` and `updateUnit` that emit updates and call `syncValue` to sync with other inputs if needed
  methods: {
    updateValue(newValue) {
      const isEmpty = newValue === '';
      const updatedValue = {
        ...this.value,
        value: isEmpty ? null : newValue,
        unit: isEmpty ? null : this.value.unit
      };
      this.$emit('input', updatedValue);

      /** Required to sync values - option-sync-item.mixin method **/
      this.syncValue(updatedValue);
    },
    updateUnit(newUnit) {
      const updatedValue = { ...this.value, unit: newUnit };
      this.$emit('input', updatedValue);

      /** Required to sync values - option-sync-item.mixin method **/
      this.syncValue(updatedValue);
    },
  },
};
</script>
