/**
 * print.mixin.js
 * This mixin provides utility functions for implementing print pagination in a Vue component.
 *
 * Methods:
 * printWindow():
 * This method calls the `window.print()` function, which prints the current window.
 *
 * getPageCount(items, itemsPerPage = 30):
 * Accepts a list of items and a number of items per page (defaulting to 30 if not specified) as input.
 * Calculates the number of pages required to display all the items.
 * Returns the number of pages required.
 *
 * getPageItems(items, currentPage, itemsPerPage = 30):
 * Accepts a list of items, a current page number, and a number of items per page (defaulting to 30 if not specified) as input.
 * Returns a subset of the items based on the current page and the number of items per page specified.
 *
 * In summary, the mixin provides utility functions for implementing pagination in a Vue component.
 * These functions include calculating the number of pages required to display a given list of items,
 * and returning a subset of the items based on the current page and the number of items per page specified.
 */
import store from '@/store';
import { computed } from '@vue/composition-api'

const isVerticalMenuCollapsed = computed(() => store.state.verticalMenu.isVerticalMenuCollapsed)

export default {
    data() {
        return {
            scaleWidth: 0,
            isContentScaled: false,
            scaler: {
                width: 0,
                max: 0,
                isScaled: false
            }
        };
    },
    created() {
        window.addEventListener('resize', this.updateContentScaleStatus);
        this.updateContentScaleStatus(); // Initialize the value on component creation
    },
    destroyed() {
        window.removeEventListener('resize', this.updateContentScaleStatus);
    },
    computed: {
        isVerticalMenuCollapsed() {
            return isVerticalMenuCollapsed.value
        }
    },
    watch: {
        isVerticalMenuCollapsed: {
            handler() {
                this.updateContentScaleStatus()
            }
        }
    },
    methods: {
        printWindow() {
            window.print();
        },
        getPageCount(items, itemsPerPage = 30) {
            if (!items || items.length === 0) {
                return 0;
            }
            let pageCount = Math.floor(items.length / itemsPerPage);
            const remainder = items.length % itemsPerPage;
            if (remainder > 0) {
                pageCount++;
            }
            return pageCount;
        },
        getPageItems(items, currentPage, itemsPerPage = 30) {
            if (!items || items.length === 0) {
                return [];
            }
            const startIndex = (currentPage - 1) * itemsPerPage;
            const endIndex = startIndex + itemsPerPage;
            return items.slice(startIndex, endIndex);
        },
        setScalerMaxWidth(width) {
            this.scaler.max = width;
            this.updateContentScaleStatus()
        },
        updateContentScaleWidth(width) {
            this.scaleWidth = width;
            this.scaler.max = width;
            this.updateContentScaleStatus()
        },
        updateContentScaleStatus() {
            this.isContentScaled = window.innerWidth <= this.scaleWidth;
            this.scaler.width = window.innerWidth;
            this.scaler.isScaled = window.innerWidth >= this.scaler.max
        }
    }
}
