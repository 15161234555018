/**
 * style.mixin.js
 * This mixin provides a utility function for generating a style attribute string from a given object. It helps
 * with processing and applying inline styles to elements in a Vue component.
 *
 * Methods:
 * computedStyle(style):
 * Accepts an object containing CSS properties and their corresponding values as input.
 * Iterates through the properties and their values, generating a style attribute string.
 * If the value is an object with 'value' and 'unit' properties, it combines them accordingly.
 * If the value is null, it skips the property.
 * Returns the generated style attribute string.
 *
 * In summary, the style.mixin.js provides a convenient way to create a style attribute string from an object
 * containing CSS properties and values. This makes it easy to apply inline styles to elements in a Vue component.
 */

export default {
    methods: {
        computedStyle(style) {
            if(style === null || style === undefined) { return '' }
            let styleAttribute = '';
            Object.entries(style).forEach(([property, value]) => {
                if (typeof value === 'object' && value !== null) {
                    const { value: propValue, unit } = value;
                    if(value.value === null) { styleAttribute += '' }
                    else {
                        // Convert camel case to hyphenated css
                        // eslint-disable-next-line no-param-reassign
                        property = property.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
                        styleAttribute += `${property}:${propValue || ''}${unit || ''};`
                    }
                }
                else {
                    // eslint-disable-next-line no-lonely-if
                    if(value === null) { styleAttribute += '' }
                    else {
                        // Convert camel case to hyphenated css
                        // eslint-disable-next-line no-param-reassign
                        property = property.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
                        styleAttribute += `${property}:${value};`
                    }
                }
            })
            return styleAttribute;
        },
    }
}
