<template>
  <div class="settings-container">
    <b-modal id="clear-settings-modal" title="Clear Settings" centered
             ok-title="Clear" ok-variant="danger"
             cancel-title="Cancel" cancel-variant="primary"
             @ok="clear()">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-html="modalText"></p>
    </b-modal>
    <b-card>
      <b-card-title class="mb-50 d-flex align-items-center justify-content-between">
        {{ title }}

        <!-- A button that clears the settings by passing an empty object to the updateSettings method. This button should open a modal to confirm the action.-->
        <b-button variant="link" size="sm" class="p-0" @click="$bvModal.show('clear-settings-modal')">
          <!--          <font-awesome-icon icon="fa-solid fa-trash" class="mr-50"></font-awesome-icon>-->
          <b-icon-trash-fill style="width: 14px; height: 14px;"/>
        </b-button>
      </b-card-title>
      <hr class="mb-50"/>
      <div class="accordion" role="tablist">
				<!-- Setting Options -->
        <slot></slot>
      </div>

      <div class="d-flex flex-row align-items-center gap-1 mt-2">
        <b-button v-if="hasChanged" size="md" variant="outline-primary" class="w-100" @click="revert">
<!--          <font-awesome-icon icon="fa-solid fa-undo" class="mr-50"/>-->
          <b-icon-arrow-counterclockwise class="mr-50" style="width: 14px; height: 14px;"/>
          <span class="align-middle">Undo Changes</span>
        </b-button>

        <b-button :disabled="!hasChanged" variant="primary" size="md" class="w-100" @click="updateSettings(settingsKey, settings)">
          <!--        <font-awesome-icon icon="fa-solid fa-save" class="mr-50"/>-->
          <b-icon-save2-fill class="mr-50" style="width: 14px; height: 14px;"/>
          <span class="align-middle">Save</span>
        </b-button>
      </div>






    </b-card>
  </div>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import settingsMixin from './settings.mixin';

export default {
  name: 'SettingsContainer',
  components: {
		BCardActions
	},
  mixins: [settingsMixin],
  props: {
    title: {
      type: String,
      required: true,
    },
    modalText: {
      type: String,
      required: false,
      default: 'Are you sure you want to delete all settings associated with this page?',
    },
    settingsKey: {
      type: String,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
    hasChanged: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    revert() {
      this.$emit('revert');
    },
    clear() {
      this.updateSettings(this.settingsKey, {}).then(() => {
        this.$emit('cleared')
      });
    },
  }
}
</script>

<style>
.options-header  {
  display: flex;
  justify-content: space-between !important;
  padding: 0.5rem 0 0 0 !important;
  /* border-bottom: 1px solid #ebe9f1 !important;*/
}
.options-collapse {
  border: 1px solid #ebe9f1 !important;
  border-radius: 0.357rem !important;
}

.options-collapse .card-body {
  padding: 1rem !important;
  border-radius: 0.357rem !important;
}

.options-collapse > .card-body > .form-group:last-child {
  margin-bottom: 0;
}

.options-divider {
  border: 1px solid #6e6b7b21;
}
</style>
