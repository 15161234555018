<template>
  <b-card-header v-b-toggle="toggleId" :class="headerClass" header-tag="header" role="tab">
    <b-button variant="transparent" class="px-0 text-left font-small-3">{{ title }}</b-button>
    <b-button variant="transparent" size="sm" class="px-0">
      <feather-icon icon="ChevronDownIcon" />
    </b-button>
  </b-card-header>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    headerClass: {
      type: String,
      required: true,
    },
    toggleId: {
      type: String,
      default: null,
    },
  },
};
</script>
